import React from "react";
import ScheduleButton from "./scheduleButton";

const Growth = (props) =>{
    return (
        <div className="growth">
            <div className="container">
            <h1 className="pb-20">Our Growth Guarantee</h1>
            <p>We Guarantee We'll Beat Your Existing Ad KPI's By At Least 25% In Under 90 Days Or </p>
            <p className="pb-20">You Don’t Pay </p>
            <ScheduleButton class="growth-shadow"/>

            <div className="pt-50 pb-50">
                <h2>Still Not Convinced? <span>Numbers Don't Lie!</span></h2>
                <p>In The First 90 Days Our Average Partner (Not Our Best):</p>
            </div>

            <div className="growth-number">
                  {props.number.map((item) => {
                    return (
                        <>
                   <div>
                    <p>{item.description}</p>
                    <h2>{item.number}</h2>
                  </div>
                   
                        </>
                    )
                  })}
                 
                
            </div>
            </div>
        </div>
    )
}

export default Growth;