import React from "react";
import {HiOutlineArrowNarrowLeft} from "react-icons/hi"
const StepsButton = (props) => {
    return (
  
        <div className="step-container">
        <div className={` d-flex space-between steps-button ${props.display}`}>
            <div onClick={props.prev} className={`prev ${props.class}`}><HiOutlineArrowNarrowLeft/><p>Previous</p></div>
           <input type="submit" className={`next ${props.classfour}`} value={props.value}/>
        </div>
        </div>
    )
}

export default StepsButton;