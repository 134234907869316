import React from "react";

const SpaceText = (props) =>{
    return(
        <div className={`d-flex space-between approach pt-100 ${props.class}`}>
                
        <h1 className="subTitle">{props.right}</h1>
        <h1 className="subTitle w-60">{props.left}</h1>

    </div>
    )
    
}
export default SpaceText;