import React, { useEffect, useRef } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
const ModelBox = () =>{
  const box =[ 
    {
     
      icon:"../images/icon.svg",
      iconTitle:"Cross-functional versatility",
      description:" We provide your business with digital marketing capabilities by engaging a talented team with a diverse skill set, including: graphic designers, paid-media specialists, developer, copywriters and content creators",
  
    },
    {
    
      icon:"../images/icon.svg",
      iconTitle:"Senior Management",
      description:" We provide your business with digital marketing capabilities by engaging a talented team with a diverse skill set, including: graphic designers, paid-media specialists, developer, copywriters and content creators",
    },
    {
  
      icon:"../images/icon.svg",
      iconTitle:"Reliable customer service",
      description:" We provide your business with digital marketing capabilities by engaging a talented team with a diverse skill set, including: graphic designers, paid-media specialists, developer, copywriters and content creators",
    }
  ]
    const bigbox =[
      {
        number:"1",
        title:"Dedicated high-performance team",
        icon:"../images/icon1.svg",
        iconTitle1:"Cross-functional versatility",
        description1:" We provide your business with digital marketing capabilities by engaging a talented team with a diverse skill set, including: graphic designers, paid-media specialists, developer, copywriters and content creators",
        icon2:"../images/icon4.svg",
        iconTitle2:"Senior Management",
        description2:" You will also get reassurance from a senior account manager and ongoing strategy consulting from our experienced operations, marketing and finance executives",
        icon3:"../images/icon5.svg",
        iconTitle3:"Reliable customer service",
        description3:" Think of Black Bird Marketing as your internal digital marketing department, that can be relied upon even in the busiest times of the year",
        theme:{background:"#F1E7FF",text:"black"},
        model:"model1"
    
      },
      {
        number:"2",
        title:"Data-driven methods",
        icon:"../images/icon6.svg",
        iconTitle1:"Analytics at the core",
        description1:" We use sophisticated data-models and analytical techniques to measure performance and inform-decision making",
        icon2:"../images/icon7.svg",
        iconTitle2:"Continuous Testing",
        description2:"We drive our learning and insights through continuous testing of creatives, copies, placements, targeting and mores",
        icon3:"../images/icon8.svg",
        iconTitle3:"Implementation",
        description3:" We integrate the insights from our analytics to improve our clients digital marketing outcomes and create a positive growth cycle",
        theme:{background:"#F1E7FF",text:"black"},
        model:"model2"
      
      },
      {
        number:"3",
        title:"Powerful design and creative excellence",
        icon:"../images/icon9.svg",
        iconTitle1:"The user experience in focus",
        description1:"We approach the user experience as fundamental to digital marketing performance and assess qualitative aspects of the user experience to consistently optimize the customer journey.",
        icon2:"../images/icon10.svg",
        iconTitle2:"Creative expertise",
        description2:"We appreciate the fine details of creative development and leverage our expertise across industries to develop visually-striking creatives, certain to attract the interest of the target audience",
        icon3:"../images/icon11.svg",
        iconTitle3:"Design at scale",
        description3:"Black Bird Marketing has capabilities to quickly scale designs that work and produce high-volume digital marketing creatives.",
        theme:{background:"#F1E7FF",text:"black"},
        model:"model3",
   
          className:"content-unset"
      },
    ]

    
    return(
     <>
       <div className=" model">
        {
          bigbox.map((item,i)=>{
            return (
             
         <AnimationOnScroll
        animateIn="animate__slower animate__fadeInUp"
        className="model-step"
        animateOnce
        key={item.number}
      >
              <div className={`model-number ${item.className}`}>
               <p id={item.steps} className={item.model}><span>{item.number}</span></p>
              </div>
     
              <div className={`model-description ${item.model}`} id={item.model}>
              <h1>{item.title}</h1>
              <div className="model-display">
            
                    <div className="w-30">
                  
                 <div className="model-icon">
                   <img src={item.icon}/>
                   <h2 className="ptb-20"> {item.iconTitle1}</h2>
                 </div>
                 <p>
                 {item.description1}
                 </p>
                 </div>

                 <div className="w-30">
                  
                  <div className="model-icon">
                    <img src={item.icon2}/>
                    <h2 className="ptb-20"> {item.iconTitle2}</h2>
                  </div>
                  <p>
                  {item.description2}
                  </p>
                  </div>

                  <div className="w-30">
                  
                  <div className="model-icon">
                    <img src={item.icon3}/>
                    <h2 className="ptb-20"> {item.iconTitle3}</h2>
                  </div>
                  <p>
                  {item.description3}
                  </p>
                  </div>
          
             
                 </div>
              </div>
 </AnimationOnScroll>
            
            )
          })
        }
   

       </div>
     </>
    )
}

export default ModelBox;