import React from "react";
import Contact from "./contactForm";


const JobPosition = () => {


    
    return (
        <div className="job-position pt-100 pb-100 ">
           <div className="w-60">
           <h1>JOB POSITIONS</h1>
            <p>We are looking for an experienced Data Engineer to join our team.
                 We are looking for an experienced data engineer to join our team.
                  You will use various methods to transform raw data into useful data systems.
                   For example, you’ll create algorithms and conduct statistical analysis. Overall,
                    you’ll strive for efficiency by aligning data systems with business goals.
            </p>
        <p className="ptb-20">To succeed in this data engineering position,
         you should have strong analytical skills and
         the ability to combine data from different sources.
         Data engineer skills also include familiarity with several programming
         languages and knowledge of learning machine methods. If you are detail-oriented,
          with excellent organizational skills and experience in this field, we’d like to hear
           from you. Our team is supportive, hard-working, and friendly and we like to keep it this way.
            If you would like to join Frakton/Melon, here is what you will do most of the time:
         </p>
            <ul>
                <li>Analyze and organize raw data</li>
                <li>Build data systems and pipelines</li>
                <li>Evaluate business needs and objectives</li>
                <li>Interpret trends and patterns</li>
                <li>Conduct complex data analysis and report on results</li>
                <li>Prepare data for prescriptive and predictive modeling</li>
                <li>Build algorithms and prototypes</li>
                <li>Combine raw information from different sources</li>
                <li>Explore ways to enhance data quality and reliability</li>
                <li>Identify opportunities for data acquisition</li>
                <li>Develop analytical tools and programs</li>
                <li>Collaborate with data scientists and architects on several projects</li>
            </ul>
            <h2 className="ptb-20">Requirements:</h2>
            <ul>
           <li> 2+ years’ experience of Data platform implementation, including 2+ years of hands-on experience in implementation and performance tuning Data Factory/Kinesis/Kafka/Spark/Storm implementations.</li>
           <li>2+ years of IT platform implementation experience.</li>
           <li>Experience with one or more relevant tools (Flink, Spark, Sqoop, Flume, Kafka, Amazon Kinesis, Azure Data Factory).</li>
           <li>Proficiency with at least one language such as Python, Scala, C++ or Java.</li>
           <li>Basic understanding of machine learning fundamentals.</li>
           <li>Experience working on a codebase with many contributors.</li>
           <li>Written and verbal technical communication skills with an ability to present complex technical information in a clear and concise manner to a variety of audiences.</li>
           <li>Current hands-on implementation experience required.</li>


            </ul>

            <h2 className="ptb-20">Advantages:</h2>
            <ul>
            <li>     Hands-on experience working on large-scale data science/data analytics projects.</li>
            <li> Ability to lead effectively across organizations.</li>
            <li> Hands-on experience with Data Analytics technologies such as Azure, AWS, - - Hadoop, Spark, Spark SQL, MLib or Storm/Samza.</li>
            <li> Implementing Azure and AWS services in a variety of distributed computing, enterprise environments.</li>
            <li> Experience with at least one of the modern distributed Machine Learning and Deep Learning frameworks such as TensorFlow, PyTorch, MxNet Caffe, and Keras.</li>
            <li> Experience building large-scale machine-learning infrastructure that has been successfully delivered to customers.</li>
            <li> Experience defining system architectures and exploring technical feasibility trade-offs.</li>
            <li> 2+ years of experience developing cloud software services and an understanding of design for scalability, performance, and reliability.</li>
            <li> Ability to prototype and evaluate applications and interaction methodologies.</li>
            <li> Experience with Azure and AWS technology stack.</li>
            <li> Ability to take Machine Learning models and implement them as part of the data pipeline.</li>
            </ul>
           </div>

          
          <Contact/>
        </div>
    )
}

export default JobPosition;












