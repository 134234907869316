import React, { useEffect, useRef } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ApproachBox from "../Components/Layout/AproachBox";

import Slider from "../Components/Layout/Slider";
import WebBox from "../Components/Layout/Web";
import { AnimationOnScroll } from "react-animation-on-scroll";
import SpaceText from "../Components/Layout/SpaceText";
import Testimonials from "../Components/Layout/testimonial";
import {BsArrowRightShort} from "react-icons/bs"

import Loader from "../Components/Layout/animation";
import { Link } from "react-router-dom";
import FlyPages from "../Components/Layout/flyPages";

const Homepage =(props)=>{

   const client = [
        {
            image:"../images/Nike.svg",
            key:"1"
        },
        {
            image:"../images/inter.svg",
            key:"2"
        },
        {
            image:"../images/ireland.svg",
            key:"3"
        },
        {
            image:"../images/form.svg",
            key:"4"
        },
        {
            image:"../images/ins.svg",
            key:"5"
        },
        {
            image:"../images/buster.svg",
            key:"6"
        },
        {
            image:"../images/clean.svg",
            key:"7"
        },
        {
            image:"../images/fia.svg",
            key:"8"
        },
        {
            image:"../images/Loti.svg",
            key:"9"
        },
        {
            image:"../images/atto.svg",
            key:"10"
        },
    ]

    const text =
        {
            text1:"Approach",
           text2:"We are Black Bird Marketing. We partner with brave clients to build brand experiences people love."
        }
    

    return (
                  <>
                           <div className="homepage ">
            <div className="homepage-light pt-50">
            <div className="container space-between d-flex pb-50">
         
         <h1 className="title main-title">The Fully Charged <br/> Digital Powerhouse!</h1>
     <div className="header-description">
     <p>Blackbird is invested in integrating data-driven marketing models and customer experience design in helping clients become true disruptors in the subscription box and e-commerce industries. </p>
        <div className="header-button ">
    <Link className="first-link" to="/steps">Let's Fly</Link>
   <div className="arrow-header">
   <Link to="/work">Our Work
   <BsArrowRightShort/></Link>
     </div>
 </div>

  
   </div>
   </div>   

            </div>
        
        <Slider/>

        <div className="container">
                       <AnimationOnScroll
               animateIn="animate__slower animate__fadeInUp"
             
               animateOnce
             >
    
            {/* <SpaceText right={text.text1} left={text.text2}/> */}
            </AnimationOnScroll>
            <ApproachBox/>
        </div>
        <Loader theme={props.theme}/>
      <Testimonials/>
       {/* <div className="bbm-fixed">
       </div> */}

        <div className="webBox-bck pt-100 ">
            <div className="container ">
            <AnimationOnScroll
            animateIn="animate__slower animate__fadeInUp"
          
            animateOnce
          >   <h1 className="subTitle ">We partner with brave clients to drive measurable results by building digital experiences people love.  Explore some of our best work below, among 100+ digital campaigns launched this year.</h1> </AnimationOnScroll>
            <WebBox/>
            </div>
        </div>

        <div className="container clients pt-100">
            <h1 className="subTitle">Our Clients</h1>
            <div className="client-display space-between  pb-100
            pt-50   "> 
              {client.map((item) => {
                return (
                   <div key={item.key}>
                     <LazyLoadImage src={item.image}  className="w-100"  width="100%" effect="blur" />
                   </div>
                )
              })}
            </div>
        </div>
        
       </div>
       <FlyPages class="fly-pages"/></>
    )
}

export default Homepage ;