import React from "react";
import Lottie from "react-lottie-player";
import Loader1 from "../Layout/bbm2.json"
import Loader2 from "../Layout/bbmv3.json"
import Loader3 from "../Layout/bbm3.json"
function Loader(props) {
  return (
    <div className="container animaton">
    <div>
    <h1>We fuel growth with a proprietary, integrated model built to optimize the efficiency and effectiveness of your ad spend.</h1>
    </div>
      <div className="animation-display">

      <p className="creative-testing">Creative Testing</p>
      <div class="creative-description">
				<p>Performance Creative</p>
        <p>High-tempo Ad Testing</p>
        <p>OTT Advertisements</p>
			</div>


      <p className="paid">Paid Acquisition</p>
      <div class="paid-description">
			<p>Paid Search</p>
      <p>Paid Social</p>
      <p>Performance Influencer</p>
			</div>

      <p className="on-site">On-Site Optimization</p>
      <div class="on-site-description">
			 <p>Conversion Rate Optimization</p>
       <p>Landing Pages</p>
       <p>Search Engine Optimization</p>
			</div>



      <p className="retention">Retention Marketing</p>
      <div class="retention-description">
				<p>Email Marketing</p>
        <p>SMS Marketing</p>
        <p>Loyalty Programming</p>
			</div>
      {props.theme === "dark" ?   <Lottie
      className="animation-desktop"
        loop
        animationData={Loader3}
        play
        style={{ width:750, height: 750 }}
      /> :   <Lottie
      className="animation-desktop"
        loop
        animationData={Loader1}
        play
        style={{ width:750, height: 750 }}
      />
       }


{props.theme === "dark" ?   <Lottie
      className="animation-mobile"
        loop
        animationData={Loader3}
        play
        style={{ width:350, height: 350 }}
      /> :       <Lottie
      className="animation-mobile"
     loop
     animationData={Loader2}
     play
     style={{ width:350, height: 350 }}
   />
       }
    
  
      </div>
    </div>
  );
}

export default Loader;