import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const FirstSection = (props) =>{
    return (
        <div className="w-100 project-bck">
           {/* <h1 className="title">{props.text}</h1> */}
           <LazyLoadImage src={props.image}  width="100%" effect="blur" className="project-desktop" />
           <LazyLoadImage src={props.mobile}  width="100%" effect="blur" className="project-mobile"/>
        </div>
    )
}

export default FirstSection;