import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const NinthSection = (props) => {
    return(
        <>
          <div className="container d-flex pt-100 web-swiper">
              <div className="w-49">
                 <LazyLoadImage src={props.image}     width="100%" effect="blur" />
              </div>
              <div className="w-49">
                <h1>{props.title}</h1>
                <p className="ptb-20">{props.description}</p>
              </div>
          </div>
          </>
    )
}

export default NinthSection;