import React from "react";
import { Link } from "react-router-dom";


const Footer2 = ()=>{
    
    return (
   <div className="footer">
       <div className="container">
            <div className="footer-displayy d-flex">
                <div className="w-20">
                <Link to="/"><img src="../images/bird.svg"/></Link> 
                <p>We challenge<br/> core assumptions.</p>
                <Link>info@blackbird.marketing</Link>
                </div>
           
           <div  className="footer-links">
           <div>
                <h1>What we do</h1>
                {/* <Link to="/">Home</Link> */}
                <Link to="/work">Work</Link>
                {/* <Link to="/services">Services</Link> */}
                <Link to="/steps">Contact us</Link>
                {/* <Link>Client Help Desk</Link> */}
                
            </div>
                   
            <div>
                <h1>Who we are</h1>
                <Link to="/our-model">Our Model</Link>
                <Link to="/careers">Join Us</Link>
      
            </div>

                   
            <div>
            <h1>Work</h1>
            {/* <Link to="/three60">Three60</Link> */}
                <Link to="/formify">Formify</Link>
                <Link to="/finser">Finser</Link>
                <Link to="/instinct">Instinct</Link>
                 <Link to="/buster-box">Busterbox</Link>
                 { /*
                <Link to="/intersport">Intersport</Link>
                <Link to="cleanfit">CleanfitBox</Link>
                <Link to="/myireland-box">MyirelandBox</Link>
        */}
                <Link to="/work">See All</Link>
                
            </div>
            <div>
                <h1>Follow us</h1>
                <a target="_blank" href="https://www.instagram.com/blackbirdmarketing/">Instagram</a>
                <a target="_blank" href="https://fj.linkedin.com/company/blackbirdmarketing">Linkedin</a>
                <a target="_blank" href="https://www.facebook.com/blackbirdks/">Facebook</a>
             
            </div>
           </div>
            </div>
      </div>
   </div>
    )
}

export default Footer2;