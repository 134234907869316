import React, { useEffect, useRef, useState } from "react";
import CareerText from "../Components/Layout/careersText";


import FirstSection from "../Projects/backgroundPro";
import SecondSection from "../Projects/Values";
import ThirdSection from "../Projects/Location";
import FourthSection from "../Projects/Portal";
import FifthSection from "../Projects/Gradient";
import SixthSection from "../Projects/number";
import SeventhSection from "../Projects/Client";
import EighthSection from "../Projects/Phone";
import TenthSection from "../Projects/secondBanner";
import NinthSection from "../Projects/webDesign";
import EleventhSection from "./Collage";
import FlyPages from "../Components/Layout/flyPages";

const CleanFit = () =>{
    const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
    const ValueBox = [
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
    ]
    const branding = {
        branding:"Web design, development and performance marketing",
        title:"Create a high-performing digital presence for a box tailored to fuel and nurture physical wellbeing. ",
        description:"We re-designed and re-developed Clean Fit Box’s website to incorporate all our learnings and expertise in the subscription-box business model. The end result is a high-performance website that is optimized for conversion. That, together with a revamped digital marketing strategy, has seen Clean Fit Box establish itself as one of the most exciting boxes in the space of health and wellness. "
   }
   const Expertice = [
    {
        title:"Expertise",
        des1:"Website Development",
        des2:"User Experience and Interface Design",
        des3:"Email marketing",
        des4:"Performance marketing"
    },
  
 ]
const  gradient = [
    {
        camera:"with ultra wide-angle camera"
    },
    {
        camera:"with the main camera"
    },
    {
        camera:"with the tele camera"
    },
  ]

    const secondText =
    {
        text1:"Clean Fit",
     

    }
     const collage = [
        {
            image:"../images/img1.webp",
             class:"collage-img1"
        },
        {
            image:"../images/img2.webp",
            class:"collage-img2"
        },
        {
            image:"../images/img2.webp",
            class:"collage-img2"
        },
        {
            image:"../images/img1.webp",
            class:"collage-img1"
        },
     ]
     const stats = [
        {
          number:"xx%",
          title:"",
          description:"increase in YOY       revenue "
        },
        {
          number:"xx%",
          title:"",
          description:"decrease in YOY churn-rates"
        },
   
     ]
      
     useEffect(() => {
      
        const handleResize = () => changeDeviceSize(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);   

     const ref = useRef(null);
     const handleClick = () => {
    
      ref.current?.scrollIntoView({ behavior: "smooth" });
      
     };
    return (
       <>
        <div className="container">
        
        <CareerText right={secondText.text1} left={secondText.text2}  link={secondText.link}/>
      

        </div>
        <FirstSection image="../images/cleanfitbanner.webp" mobile="../images/cleanfitmobile.png"/>
        <SecondSection  item={Expertice} branding={branding.branding} title={branding.title} description={branding.description}/>  
    
        <ThirdSection function={handleClick} video="../images/cleanfit.mp4" class="cleanfit-color"/>
     
        <FourthSection/>
        <FifthSection gradient={gradient}/> 
        {/* <SixthSection/> */}

        <SeventhSection video={"../images/cleanfit.mp4"} stats={stats} title="Process and Outcomes" description="CleanFit approached Black Bird Marketing at the beginning of 2022 with the need to revamp their digital platform and conversion strategy. Our team engaged in several consultations with the client and each department completed a thorough audit of Clean Fit Box. The client loved our recommendations and after months of work we launched the new-look www.cleanfitbox.com - a new-platform with all the capabilities to reach Clean Fit’s ambitious business objectives." />

        <NinthSection image="../images/cleanswiper.png" title="Optimized for Conversion" description="We incorporated our proprietary sing-up flow to Clean Fit’s website, allowing them to capture more leads and understand the target customer better. This has led to a seamless user experience that is optimized for conversion. Coupled with the modern and minimalist design of the website, the integrated conversion features have transformed Clean Fit’s digital platform into a high-performance driver of business."/>
    
      <div ref={ref}>
      <TenthSection  image="../images/cleanscbanner.webp" class="cleanfit-color" title="Capturing new customers at a high-rate" description="Along with the re-development and re-design of the platform, Black Bird Marketing is engaged in crafting new email campaigns and digital ad campaigns, tailored to reach new audiences and capture previously untapped market share. Our holistic approach to performance marketing has seen Clean Fit adopt a brand-new sales funnel and improve in virtually every key performance metric."/>
      </div>
  
        <EleventhSection image={collage}/>
        <FlyPages class="cleanfit-color"/>
        </>
    )
}

export default CleanFit;