import React from "react";

const TenthSection = (props) => {

    return ( 
        <div className="pt-100" >
            <div className="fixed-banner" style={{backgroundImage:`url(${props.image})`}} >
            {/* <img src={props.image} className="w-100"/> */}
            <div className="container" >
               <div className={`solution ${props.class}`} >
               <h1>{props.title}</h1>
                <p className="ptb-20">{props.description}</p>
               </div>
                </div>
          </div>

          <div className="solution-two container">
            <img src={props.secondImage} className="w-100"/>
          <h1>{props.title}</h1>
                <p className="ptb-20">{props.description}</p>
          </div>

        </div>
    )
}
export default TenthSection;