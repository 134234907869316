import React from "react";

const ScheduleButton = (props) => {
    return (
        <>
               <a className={`schedule-button ${props.class}`} href="https://calendly.com/blackbirdmeet/schedule">Schedule your free growth Call</a>
        </>
    )
}

export default ScheduleButton;