import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import FlyPages from "./flyPages";
import WebBox from "./Web";

const Work = () =>{
    return (
        <div className="work-box pt-100 ">
        <div className="container ">
        <AnimationOnScroll
        animateIn="animate__slower animate__fadeInUp"
      
        animateOnce
      >   <h1 className="subTitle ">We partner with brave clients to drive measurable results by building digital experiences people love.  Explore some of our best work below, among 100+ digital campaigns launched this year.</h1> </AnimationOnScroll>
        <WebBox/>
        </div>
        <FlyPages class="fly-pages"/>
    </div>
    )
}

export default Work;