import React from "react";
import { Link } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";
const WebBox = () => {
     const Box = [
          
        {
            title:"BusterBox",
            subTtile:"Re-engineering digital experiences & strategy",
            image:"../images/buster1.webp",
            video:"../images/buster.mp4",
            link:"/buster-box",
            badges:"../images/badges.svg",
            badgesText:"105% Increase in Email Revenue"
        },
        {
            title:"Formify",
            subTtile:"Building the worlds first no-code, drag and drop, sign-up flow builder",
            image:"../images/formify1.webp",
            video:"../images/formify.mp4",
            link:"/formify",
     
        },

        {
            title:"InterSport   ",
            subTtile:" Enabling the leading sportswear retailer to dominate e-commerce.",
            image:"../images/int.webp",
            video:"../images/int.mp4",
            link:"/intersport",
            badges:"../images/badges.svg",
            badgesText:"25% Increase in Online Sales"
        },
        {
            title:"three60",
            subTtile:"Building the brand and voice for an artist management company",
            video:"../images/ylivideo.mp4",
            image:"../images/ylilimauni.png",
            
 
        },
        {
            title:"Instinct",
            subTtile:"Award winning influencers management platform in SEE",
            image:"../images/instinct.webp",
            video:"../images/instinct.mp4",
            link:"/instinct",
  
        },
        {
            title:"MyIrelandBox",
            subTtile:"Subscription Box conversion-optimized platform and strategy.",
            image:"../images/irelandbox.webp",
            video:"../images/irelandbox.mp4",
            link:"/myireland-box",
            badges:"../images/badges.svg",
            badgesText:"48% Increase in YOY Revenue"
        },



     
        {
            title:"CleanFitBox",
            subTtile:"High-performing digital presence",
            image:"../images/cleanfit.webp",
            video:"../images/cleanfit.mp4",
            link:"/cleanfit",
           
        },

        {
            title:"Finser",
            subTtile:"A brand that communicates competence and innovation.",
            image:"../images/finser.webp",
            video:"../images/finser.mp4",
            link:"/finser",
   
        },
     
   
      
     ]
      
    return (
    
         <div className="pt-100  pb-100 web-box">
          
          {Box.map((item)=>{
          return(
            <AnimationOnScroll
            animateIn="animate__slower animate__fadeInUp"
            className="hoverweb-box"
            animateOnce
            key={item.title}
          >
            <Link className=" w-100" to={item.link}>
            <div >
                <p className="web-color"><b>{item.title}</b></p>
                <p className="web-color"> {item.subTtile}</p>
            </div>
            <figure className="video-animate ">
                <img src={item.image} className="w-100 img-animate"/>
                <video autoPlay loop muted playsInline className="w-100 display-video"> 
                <source src={item.video} type="video/mp4" />
                </video>
                <div className="badges">
                <img src={item.badges} className="badges-img"/> 
                <div className="badges-text">
                <img src="../images/+.svg"/>
                <p className="badges-text2">{item.badgesText}</p>
                </div>
       
            </div>
            </figure>
          
        </Link>
        </AnimationOnScroll>
          )
      })} 
          

       </div>
    )
}

export default WebBox;