

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import disableScroll from "disable-scroll";
import Hamburger from "hamburger-react";
import { FiArrowUpRight } from "react-icons/fi";
import { useRef } from "react";

const LandingNav = (props) =>{
    const [isOpen, setOpen] = useState(false);
 
    const [active, setActive] = useState(1)


    useEffect(() => {
        
    }, [disableScroll]);
    isOpen ? disableScroll.on() : disableScroll.off();  
    return (
       <div  className="nav schedule-nav">

        <div className="container d-flex space-between ">
             <div >
            {isOpen === true || props.theme === "dark" ? <Link to="/" onClick={() => {setOpen(false); setActive(1) ;}} className={`${active === 1 ? "nav-active" : ""}`}><img src="../images/bbmwhite.svg"></img></Link> : <Link to="/" onClick={() => {setOpen(false); setActive(1) ;}} className={`${active === 1 ? "nav-active" : ""}`}><img src="../images/Logo.svg"></img></Link> }  
             </div>
       
             <div  className={
              isOpen === true ? "navbarlist" : ""
            }>
               <ul className="w-100 landing-list">
          
               <li onClick={props.function}  className={`${active === 2 ? "nav-active" : ""}`}>          
                  CASE STUDIES     
                  </li>
                  <li onClick={props.function2}>         
                  OUR GUARANTEE           
                  </li>
                  <li className="landing-fly" onClick={props.function3}>               
                  Let's Fly
                  </li>
               
                 <li className="hamburger-menu-mobile schedule-hamb">
                    <div className={
                     isOpen === true ? "hamburger-menu navbarlist" : "hamburger-menu"}>
                       <Hamburger
                       toggled={isOpen}
                        toggle={setOpen}
                       size={25}
                       direction="right"
                        />
                     </div>
                </li>
        <div
            className={
              isOpen ? "mobile-menu-container opened" : "mobile-menu-container"
            }
           >
          <ul>
            {isOpen ? (
              <>

           <div className="container">
            <Link to="/work" className="work-menu" onClick={() => setOpen(false) }>
            <h1 >Work</h1>
            <FiArrowUpRight/>
            </Link>
           <div className=" d-flex nav-hamb">
          
           </div>

           <div className="mobile-list">
             <p onClick={() => setOpen(false) || props.function}>CASE STUDIES</p>
             <p onClick={() => setOpen(false) && (props.function2)}>OUR GUARANTEE</p>
             <p onClick={() => setOpen(false) && (props.function3)}>GET A PRICE</p>
               </div>

                <div  className="Schedule">
                <a  href="https://calendly.com/blackbirdmeet/schedule" target="_blank">Schedule a Call<FiArrowUpRight/></a>
          
                </div>
               
           </div>
         
                    </>
                   ) : null}
                </ul>
            </div>
               </ul>
             </div>

        </div>

       </div>
    )
}


export default LandingNav;