import React from "react";


const ClientColor = () => {
    const logo = [
    
        {
            image:"../images/intercolor.svg",
        },
        {
            image:"../images/nikecolor.svg",
        },
        {
            image:"../images/mibcolor.svg",
        },
        {
            image:"../images/formcolor.svg",
        },
        {
            image:"../images/inscolor.svg",
        },
        {
            image:"../images/buscolor.svg",
        },
        {
            image:"../images/cleancolor.svg",
        },
        {
            image:"../images/fiacolor.svg",
        },
        {
            image:"../images/lotcolor.svg",
        },
        {
            image:"../images/attocolor.svg",
        },
    ]
    return (
        <div className="client-color pt-100">
            <h1>Our Clients</h1> 
            
            <div className="client-logo-display">
                {logo.map((item) => {
                    return (
                       <div>
                          <img src={item.image} alt="#"/>
                       </div>
                    )
                })}
       
            </div>
        

        </div>
    )
}

export default ClientColor;