import React from "react";

const SeventhSection = (props) => {
    return (
        <div className="client-bck pt-50 pb-50">
            <div className={`container ${props.class}`}>
                 <div className="client-d">
                    <h1 className="subTitle">{props.title}</h1>
                    <p className="ptb-20">{props.description}</p>
                    <div className="client-box">
                        {props.stats.map(item => {
                            return (
                                <div  key={item.title}><h1>{item.number}</h1> <p ><b>{item.title}</b></p> <p>{item.description}</p></div>
                            )
                        })}
                    {/* <div ><h1>99%</h1> <p ><b>Producs sold</b></p> <p>Vestibulum ante ipsum primis.</p></div>
                        <div ><h1>270+</h1> <p ><b>Clients Daily</b></p> <p>Vestibulum ante ipsum primis.</p></div>
                         */}
                    </div>
                  
                 </div>

               
                 <video autoPlay loop muted playsInline className="client-img"> 
                 <source src={props.video} type="video/mp4"  className="w-100"/>
                </video>
           
               
            </div>
        </div>
    )
}

export default SeventhSection;