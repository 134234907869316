import React from "react";


const Header = (props) => {
    return (
        <div className="studies pt-50 pb-50">
        <p>CASE STUDIES</p>
        <h1>Check Out What Our <span>Existing Partners</span> Have To Say About Us</h1>
    </div>
    )
}

export default Header;