
import React from "react";
import { Link } from "react-router-dom";


const FlyPages = (props) =>{

    return(
      <>
      <div className="fly-bck">
              <div className="container">
                  <h1 >Ready? Let's be perfect digital partners</h1>
                  <Link to="/steps" className={props.class}>Let's Fly</Link>
              </div>
      </div>
      </>
    )
}

export default FlyPages;