import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Sling as Hamburger } from "hamburger-react";
import Button from "../button";
import WebBox from "./Web";
import { AnimationOnScroll } from "react-animation-on-scroll";
import {FiArrowUpRight} from "react-icons/fi";
import disableScroll from "disable-scroll";
// import ReactSwitch from "react-switch";
const Nav =(props)=>{
    const [isOpen, setOpen] = useState(false);
    const [active, setActive] = useState(1)
    useEffect(() => {

    }, [disableScroll]);
    isOpen ? disableScroll.on() : disableScroll.off();  
    return (
       <div  className="nav ">

        <div className="container d-flex space-between ">
             <div >
            {isOpen === true || props.theme === "dark" ? <Link to="/" onClick={() => {setOpen(false); setActive(1) ;}} className={`${active === 1 ? "nav-active" : ""}`}><img src="../images/bbmwhite.svg"></img></Link> : <Link to="/" onClick={() => {setOpen(false); setActive(1) ;}} className={`${active === 1 ? "nav-active" : ""}`}><img src="../images/Logo.svg"></img></Link> }  
             </div>
       
             <div  className={
              isOpen === true ? "navbarlist" : ""
            }>
               <ul className="w-100">
               <li>
                  <Link to="/careers" onClick={() => {setOpen(false); setActive(2) ;}}  className={`${active === 2 ? "nav-active" : ""}`}>
                    Join Us
                  </Link>
                  </li>
                  <li>
                  <Link to="/our-model" onClick={() => {setOpen(false); setActive(3) ;}} className={`${active === 3 ? "nav-active" : ""}`}>
                    Our Model
                  </Link>
                  </li>
                  {/* <li onClick={() => {setOpen(false); setActive(4) ;}} className={`switch ${active === 4 ? "nav-active" : ""}`}>
                     <label>{props.theme === "light" ? "☀" : "☾"}</label>
                    <ReactSwitch onChange={props.change} checked={props.check}/>
                  
                  </li> */}
                
                 <li className="hamburger-menu-mobile">
                    <div className={
                     isOpen === true ? "hamburger-menu navbarlist" : "hamburger-menu"}>
                       <Hamburger
                       toggled={isOpen}
                        toggle={setOpen}
                       size={25}
                       direction="right"
                        />
                     </div>
                </li>
        <div
            className={
              isOpen ? "mobile-menu-container opened" : "mobile-menu-container"
            }
           >
          <ul>
            {isOpen ? (
              <>

{/*                  
        <div className="container ">
     
        <WebBox/>
        </div> */} 


           <div className="container">
            <Link to="/work" className="work-menu" onClick={() => setOpen(false) }>
            <h1 >Work</h1>
            <FiArrowUpRight/>
            </Link>
           <div className=" d-flex nav-hamb">
            
             <Link to="/finser" onClick={() => setOpen(false) } className="navbarlin1">  <div className="nav-overlay"><h1>Finser</h1></div>   <img src="../images/finserbanner.png"  className="w-100"/>
             <p>Finser </p></Link>
             <Link to="/formify" onClick={() => setOpen(false) } className="navbarlin2">  <div className="nav-overlay"><h1>Formify</h1></div>      <img src="../images/formify.png"  className="w-100"/>
             <p>Formify</p></Link>
             <Link to="/myireland-box" onClick={() => setOpen(false) } className="navbarlin3">  <div className="nav-overlay"><h1>MyIrelandBox</h1></div>     <img src="../images/irelandbox.webp"  className="w-100"/>
             <p>MyIrelandBox </p></Link>
             <Link to="/instinct" onClick={() => setOpen(false) } className="navbarlin4">   <div className="nav-overlay"><h1>Instinct</h1></div>    <img src="../images/instinct.webp"  className="w-100"/>
             <p>Instinct </p></Link>
             <Link to="/cleanfit" onClick={() => setOpen(false) } className="navbarlin1">   <div className="nav-overlay"><h1>Clean Fit</h1></div>    <img src="../images/cleanfit.webp"  className="w-100"/>
             <p>Clean Fit</p></Link>
             <Link to="/three60" onClick={() => setOpen(false) } className="navbarlin2">   <div className="nav-overlay"><h1>Coming soon!</h1></div>    <img src="../images/ylli.webp"  className="w-100"/>
             <p>Three60</p></Link>
             <Link to="/buster-box" onClick={() => setOpen(false) } className="navbarlin3">   <div className="nav-overlay"><h1>Buster Box</h1></div>    <img src="../images/busterbox.png"  className="w-100"/>
             <p>Buster Box </p></Link>
             <Link to="/intersport" onClick={() => setOpen(false) } className="navbarlin4">  <div className="nav-overlay"><h1>InterSport</h1></div>     <img src="../images/int.webp"  className="w-100"/>
             <p>InterSport</p></Link>
          

           </div>

           <div className="mobile-list">
           <Link onClick={() => {setOpen(false); setActive(2) ;}} className="mobile-transition4" to="/work"><p>Work</p></Link>
           <Link onClick={() => {setOpen(false); setActive(2) ;}}  className="mobile-transition2" to="/careers"><p>Join Us</p></Link>
                  <Link onClick={() => {setOpen(false); setActive(2) ;}} className="mobile-transition1" to="/our-model"><p>Our Model</p></Link>
            
                  <Link onClick={() => {setOpen(false); setActive(2) ;}} className="mobile-transition3" to="/steps"><p>Contact Us</p></Link>
                
                 <div className="mobile-menu">
                     
                 <Link to="/finser" onClick={() => setOpen(false) } className="navbarlin4 ">   <img src="../images/finser.webp"  className="w-100 mobile-image"/></Link>
                  <Link to="/formify" onClick={() => setOpen(false) } className="navbarlin4 ">  <img src="../images/formify1.webp  "  className="w-100 mobile-image"/></Link>
                  <Link to="/intersport" onClick={() => setOpen(false) } className="navbarlin4 ">  <img src="../images/int.webp"  className="w-100 mobile-image"/></Link>
                  <Link to="/buster-box" onClick={() => setOpen(false) } className="navbarlin4 ">    <img src="../images/busterbox.png"  className="w-100 mobile-image"/></Link>
                 </div>
               </div>

                <div  className="Schedule">
                <a  href="https://calendly.com/blackbirdmeet/schedule" target="_blank">Schedule a Call       <FiArrowUpRight/></a>
          
                </div>
               
           </div>
         
                    </>
                   ) : null}
                </ul>
            </div>
                   
                

              {/* <li onClick={() => {setOpen(false); setActive(5) ;}} className={`${active === 5 ? "button-active" : ""}`} >
              <Button link={"/steps"} text={"Let's Fly"} className="nav-active-fly"/>
              </li> */}
               </ul>
             </div>

        </div>

       </div>
    )
}

export default Nav ;