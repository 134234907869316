import React from "react";
import ScheduleButton from "./scheduleButton";

const Process = (props) =>{
    console.log(props.process)
    return (
     
            <div className="container pt-50 pb-100">
                <div className="process ">
                    <h1>The Blackbird Process</h1>
                      {props.process.map((item) => {
                        return (
                            <div className="process-display pt-50">
             
                            <div className="process-description">
                                 <div className="w-100">
                                 <div className="process-step">
                                
                                 </div>
                                  <h2 ><span>{item.step}</span> {item.heading}</h2>
                                 </div>
                               <p className="ptb-20">{item.description}</p>
                            </div>
                            </div>
       
                        )
                      })} 
                      <ScheduleButton class="process-button"/>
                    

                     
                </div>
            </div>
        
    )
}

export default Process;