import React from "react";


const FifthSection = (props) =>{
    return ( 
        <div className="container gradient pt-100">
            {props.gradient.map((item)=>{
                return (
                    <div>
                    <p>Up to</p>
                    <h1 >3x better photos in low light</h1>
                    <p>{item.camera}</p>
                </div>
                )
            })}
         
        </div>
    )
}

export default FifthSection;