import React, { useEffect, useRef, useState } from "react";
import CareerText from "../Components/Layout/careersText";


import FirstSection from "../Projects/backgroundPro";
import SecondSection from "../Projects/Values";
import ThirdSection from "../Projects/Location";
import FourthSection from "../Projects/Portal";
import FifthSection from "../Projects/Gradient";
import SixthSection from "../Projects/number";
import SeventhSection from "../Projects/Client";
import EighthSection from "../Projects/Phone";
import TenthSection from "../Projects/secondBanner";
import NinthSection from "../Projects/webDesign";
import EleventhSection from "./Collage";
import FlyPages from "../Components/Layout/flyPages";

const Formify = () =>{
    const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
    const ValueBox = [
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
    ]
    
const  gradient = [
    {
        camera:"with ultra wide-angle camera"
    },
    {
        camera:"with the main camera"
    },
    {
        camera:"with the tele camera"
    },
  ]

    const secondText =
    {
        text1:"Formify",
       text2:"",

    }
    const collage = [
        {
            image:"../images/form1.webp",
            class:"collage-img1"
        },
        {
            image:"../images/form2.webp",
            class:"collage-img2"
        },
        {
            image:"../images/form3.webp",
            class:"collage-img2"
        },
        {
            image:"../images/form4.webp",
            class:"collage-img1"
        },
     ]

     useEffect(() => {
      
        const handleResize = () => changeDeviceSize(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);
    
     const ref = useRef(null);
     const handleClick = () => {
    
      ref.current?.scrollIntoView({ behavior: "smooth" });
      
     };

     const branding = {
        branding:"Web design, development and performance marketing",
        title:"Building the worlds first no-code, drag and drop, sign-up flow builder",
        description:"Formify is a software-as-a-service business with a product that allows businesses to build custom step-based forms and surveys that make it easy to capture high-value data from prospects. Different from conventional form-builders, Formify allows users to fully customize the design of their signup flows, integrate with their favorite shop platform, and connect their preferred marketing automation tool.Blackbird was responsible for imagining the user experience, designing and building the platform. "
   }
   const Expertice = [
    {
        title:"Expertise",
        des1:"Website Development",
        des2:"User Experience and Interface Design",
        des3:"Email marketing",
        des4:"Performance marketing"
    },
   ]
   const stats = [

 ]
    return (
       <>
        <div className="container">
      
        <CareerText right={secondText.text1} left={secondText.text2}  link={secondText.link}/>
        

        </div>
        <FirstSection image="../images/formifybanner.webp" mobile="../images/formifymobile.png"/>
         
        <SecondSection   item={Expertice} branding={branding.branding} title={branding.title} description={branding.description}/>
        <ThirdSection function={handleClick} video="../images/formify.mp4" class="formify-color"/>
        {/* <FourthSection/> */}
        {/* <FifthSection gradient={gradient}/> */}
        {/* <SixthSection/> */}

        <SeventhSection title="Process and Outcomes" description="Blackbird was invited to build an MVP for what was initially an idea. Over the platform's development lifecycle, our team of designers and developers took the idea to the next level by building a set of tools and features that allowed the platform to move to Beta status within 6 months.

Ultimately, the end product is continuously being improved with new marketing automation tools being implemented, features being added, and emerging bugs being fixed. Formify is best used by subscription & e-commerce businesses.

"  stats={stats}video={"../images/finser.mp4"}/>

        {/* <EighthSection/> */}
        <NinthSection title="Emerging Technologies" image="../images/formifycard.webp" description="From a technical standpoint and given all the features of the product, developing Formify was a technically sizeable challenge. The state-of-the-art tech stack used to build Formify will allow the platform to scale efficiently with new products for different markets. 

The front end is built entirely on ReactJS and runs on the NodeJS backend. Users can create accounts, build their custom forms, manage the status of their forms, integrate the forms across a set of partner integrations, and more.
"/>
        <div ref={ref}>
        <TenthSection title="Limitless Integrations" description="One of the more powerful assets of Formify is its ability to integrate with third-party applications. Users can plug in their Mailchimp account and have emails, and other relevant information sent directly to their preferred list.

In a similar fashion, businesses that seek to promote or recommend a product to their prospects can integrate with their third-party e-commerce platform (i.e. Shopify, Wocommerce, Subbly, and more) to showcase their best-selling products. Power comes from  

" image="../images/formifyfixed.webp" class="formify-color" secondImage="../images/smallformify.png"/>
        </div>
        <EleventhSection image={collage}/>
        <FlyPages  class="formify-color"/>
        </>
    )
}

export default Formify;