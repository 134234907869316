import React, { useEffect, useRef, useState } from "react";
import Case from "../Landing/Case";
import ClientColor from "../Landing/ClientColor";
import Expert from "../Landing/Expert";
import Schedule from "../Landing/Schedule";
import Growth from "../Landing/Growth";
import Header from "../Landing/Header";
import LandingDescription from "../Landing/landingDescription";
import LandingNav from "../Landing/landingNav";
import Process from "../Landing/Process";




const BusterBoxLanding = () => {
    const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
    useEffect(() => {
      
        const handleResize = () => changeDeviceSize(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);

     const ref = useRef(null);
   
     const handleClick = () => {
    
      ref.current?.scrollIntoView({ behavior: "smooth" });
      
     };

     const ref2 = useRef(null);
     const handleClicktwo= () => {
    
      ref2.current?.scrollIntoView({ behavior: "smooth" });
      
     };

     const ref3 = useRef(null);
     const handleClickthree = () => {
    
      ref3.current?.scrollIntoView({ behavior: "smooth" });
      
     };
     console.log(ref.current,ref2.current,ref3.current)
    const category = [
        {
            category:"CLIENT",
            name:"BUSTER BOX",

        },
        {
            category:"INDUSTRY",
            name:"BUSTER BOX",

        },
        {
            category:"SHORT-TERM RESULTS",
            name:"BUSTER BOX",

        },
        {
            category:"LONG-TERM RESULTS",
            name:"BUSTER BOX",

        },
        {
            category:"FOUNDER",
            name:"BUSTER BOX"

        }
    ]

    const stats = [
        {
            description:"Scales Their Advertising By",
            number:"36%",
            image:"../images/Line.svg",
        },
        {
            description:"Increases Their Revenue By",
            number:"42% ",
            image:"../images/Line.svg",
        },
        {
            description:"Reduces Cost of Acquiring New Customers By",
            number:"25%",
            image:"",
        },
    ]

    const number = [
        {
            description:"Scales Their Advertising By",
            number:"36%",
          
        },
        {
            description:"Increases Their Revenue By",
            number:"42% ",
         
        },
        {
            description:"Reduces Cost of Acquiring New Customers By",
            number:"25%",
          
        },
    ]


    const process = [
        {
            step:"Step #1:",
            heading:"The Blackbird Digital Presence Diagnosis",
            description:"We only take on clients who can benefit from our process so before we even think of accepting money from you we jump on a quick diagnostic call to see if we think we’re a good fit for each other. If that goes well we’ll do a complete audit on your ads, websites and digital media content to make sure we can actually help. This gives us both a chance to get to know what it's like to work with each other without unneeded pressure."
        },
        {
            step:"Step #2:",
            heading:"Blackbird core fixes and longer-term planning",
            description:"Our dedicated team of experts typically identifies common mistakes and areas for improvement that are guaranteed to deliver results for your business immediately.. At the same time, our strategists and digital experts will engage with you on deeper consultations sessions to draft a longer-term strategy to make your brand a powerhouse in your niche."
        },
        {
            step:"Step #3:",
            heading:"Test, Adapt, Deliver",
            description:"We never pretend to get the solutions to all your problems in one-go. That is why our team is committed to a reiterative process which sees us test various optimizations, creatives, landing pages, email headlines and more. This way, we ensure that your brand is always adapting to market trends and needs. We’re always on our toes to make sure we give you a head-start."
        },
        {
            step:"Step #4:",
            heading:"Sales take flight",
            description:"After implementing our quick fixes, launching the long-term strategy and deriving actionable insights from the reiterative test, adapt, deliver cycle, your business will be setup for sustained growth in revenue coming in, month after month. We’ll become one of your most valuable assets as you focus your precious time and effort into other important areas of your business and rest assured your digital marketing performance is thriving."
        },
 

    ]
  const content= {
    title : "If You Are In the Subscription Box business, You Are Competing In The Most Competitive Niche",
    firstParagraph : "As a smaller brand , you face an uphill battle to conquer the niche in which your box is competing. To make things harder, you’ve put in a lot if time and effort to be able to afford to spend the big bucks on ads because you’re just at the level where the ad platforms start to think about how can they charge you more to drive their own profits, not yours. More often than not, most of our clients started losing at least 25% of ad profits every month once they cross a certain threshold of monthly ad spend, typically $10k. - and no business on earth can afford to leave money on the table in this manner. Ad platforms like Google and Facebook are setup to maximize their bottom line, not yours.. As a smaller brand they aren’t doing you any favors. That makes complete sense for them. They’ll steer you away from the options to maximize your ROAS because they know as a small brand they can convince you to spend more on pointless ads. They’ll tell you this is “best practice” for your campaigns but if you take a peek behind the curtain (like we have) you’ll notice that “best practice” translates to more revenue for them, not for you. It doesn’t increase your ROAS. It doesn’t increase your market share..",
    secondParagraph :"  That's where we come in. From our experience managing over $1 million ad spend across multiple campaigns, clients, and sectors, we’ve accumulated the knowledge on how to hack-the-system and deliver results similar to the ad strategies these platforms implement for the big brands. It's how we brought Buster Box, our first large client, from 180k to over 1 million in ad revenue per month. It’s how we increased My Ireland Box’s  ad profits by 39% in 90 days and Cooper & Kid’s by 28% in 60 days. You can check out their full results below but first, we’ll give you insights on our work model and how we achieve these results."

  }
    
        const script = document.createElement("script");
        script.src = "/path/to/resource.js";
        script.async = true;
        document.body.appendChild(script);
    
    return (
            <>
          
            <LandingNav function={handleClick} function2={handleClicktwo} function3={handleClickthree}/>
                   <div className="container landing">
        
            <Schedule stats={stats} />
               </div>
               <div className="landing-bck">
                     <div className="container">

                     <ClientColor/>
            <Header/>
            <div ref={ref}>
            <Case category={category} />
            </div>
           
            <LandingDescription title={content.title} firstParagraph={content.firstParagraph} secondParagraph={content.secondParagraph}/>
   
         </div>
         <div ref={ref2}>
         <Growth number={number}/>
      
 
           <Process process={process}/>
           <Expert/>
      
  

        <div className="container pb-50" ref={ref3}>
           <iframe id="schedule-iframe" src="https://calendly.com/blackbirdmeet/schedule"  name="myiFrame" scrolling="no" style={{width:"100%", height:"800px" , overflowY:"hidden", boder:"none"}}  frameborder="1" marginheight="0px" marginwidth="0px" height="400px" width="600px" allowfullscreen></iframe>
        </div>

                     </div>
             </div>
       </>
    )
}

export default BusterBoxLanding;