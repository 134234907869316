import React from "react";
import { Link } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";
const CareerText = (props) =>{
    return(
          <AnimationOnScroll
        animateIn="animate__slower animate__fadeInUp"
      
        animateOnce
       className={`d-flex  careers-text pt-100 pb-100 ${props.class}`}>
                
        <h1 className="smallTitle">{props.right}</h1>
       <div className="w-49">
       <p className="subTitle ">{props.left}</p> 
         <Link to={props.link}>{props.text}</Link>
       </div>

       </AnimationOnScroll>
    )
    
}
export default CareerText;