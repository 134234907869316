import React, { useState } from "react";
import StepsButton from "../Layout/stepsButton";
import StepHeading from "../Layout/stepsHeading";

const StepTwo = (props) =>{
    const [message, setMessage] = useState("");
    const [checkedValues, setCheckedValues] = useState(props.data.picked);


    const handleSubmit = (e) => {
        e.preventDefault();
        if (validation(checkedValues)) props.next("picked", checkedValues);
        else setMessage("Select one of the options!");
      };
    
      const checkChange = (e) => {
        if (e.target.checked) {
          setCheckedValues((current) => [...current, e.target.value]);
        } else {
          setCheckedValues(
            checkedValues.filter((val, i) => val !== e.target.value)
          );
        }
      };


    const heading = {  
        title:"All Services",
        subTitle:"Pick a seat, or two..."
    }
    const checkbox = [
        {
            name:"Digital Strategy",
            id:"Strategy",
            
        },
        {
            name:"Social Media Management",
            id:"Management",
            
        },
        {
            name:"Branding",
            id:"Branding",
            
        },
        {
            name:"Web & App Development",
            id:"Development",
            
        },

        {
            name:"UI/UX Services",
            id:"Services",
            
        },
        {
            name:"Print Design",
            id:"Design",
            
        },
        {
            name:"Others",
            id:"Others",
            
            
        }
    ]
    const validation = (val) => {
        // return true;
        var group = document.radioForm.picked;
        console.log("asdasdada",group);
        for (var i = 0; i < group.length; i++) {
          if (group[i].checked) break;
        }
        if (i === group.length) {
          alert("No radio button is checked");
          return false;
        } else {
          return true;
        }
   
      };
    return (
   <>
        <div className="step-container  stepTwo ">
        <StepHeading heading={heading.title} title={heading.subTitle}/>
        <form onSubmit={handleSubmit}  name="radioForm">
          <div className="steps-height">
          <div className="d-flex space-between steptwo-check ">
         {checkbox.map((item) =>{
            return (
            <div className="w-49"   key={item.id}>
          <input type="checkbox" name="picked" id={item.id} onChange={checkChange}  defaultChecked={checkedValues.includes(item.id)}
               value={item.id}></input>
          <label htmlFor={item.id} >{item.name}</label>
      
         </div>
            )
         })}
        {message ? <p className="select-msg">{message}</p> : null}
         
         </div>
          </div>

        
         <StepsButton prev={props.prev} value="Next Step"/>
        </form>
     </div>
     
   </>
    )
}

export default StepTwo;