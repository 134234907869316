import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { AnimationOnScroll } from "react-animation-on-scroll";
// Import Swiper styles
import "swiper/css";
import { Autoplay } from "swiper";
// import "./styles.css";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';



const Slider = ()=> {
//   const [cursorX,setCursorX] = useState();
//   const [cursorY,setCursorY] = useState();

// window.addEventListener("mousemove",(e) => {

//   setCursorX(e.pageX)
//   setCursorY(e.pageY)
// })

  return (
    <>

      <Swiper watchSlidesProgress={true}   
          modules={[Autoplay]}
        //   watchOverflow="hidden"
          scrollbar={{ draggable: false }}
          clickable={true}
          centeredSlides={true}
          loop={true}
          speed={5000}
          autoplay={{
          delay: 0,
        }}
        // slidesPerView={2.4} 
        breakpoints={{
          320: {
            slidesPerView: 1,
          
          },
          600: {
            slidesPerView: 1,
        
          },
          700: {
            slidesPerView: 2,
          
          },
          800: {
            slidesPerView: 2,
        
          },
          900: {
            slidesPerView: 2,
         
          },
          1200: {
            slidesPerView: 2.4,
          },

    
        }}
          className="cursor-custom pt-100">
                  
 
       <div className="swiper-wrapper ">
      

       <SwiperSlide className="slider-position"> 
            <Link to="/formify">
                <LazyLoadImage src="../images/formify1.webp" className="w-100"  width="100%" effect="blur" />
             
            </Link>
        </SwiperSlide>
        
        <SwiperSlide className="slider-position">    <Link to="/buster-box" >
                <LazyLoadImage src="../images/buster1.webp"  className="w-100" width="100%" effect="blur" />
               
            </Link></SwiperSlide>
            <SwiperSlide className="slider-position">    <Link to="/finser">
                <LazyLoadImage src="../images/finser.webp"  className="w-100" width="100%" effect="blur" />
 
            </Link> </SwiperSlide>
    
            <SwiperSlide className="slider-position">    <Link to="/instinct">
                <LazyLoadImage src="../images/instinct.webp"  className="w-100"  width="100%" effect="blur"  />
        
            </Link></SwiperSlide>
        <SwiperSlide className="slider-position">    <Link to="/buster-box">
                <LazyLoadImage src="../images/buster1.webp"  className="w-100"  width="100%" effect="blur" />
            
            </Link></SwiperSlide>
        
        
        <SwiperSlide className="slider-position">    <Link to="/intersport">
                <LazyLoadImage src="../images/int.webp"  className="w-100" width="100%" effect="blur"  />
 
            </Link>
          </SwiperSlide>

        

       </div>

      </Swiper>

    </>
  );
}

export default Slider;