import React, { useEffect, useRef, useState } from "react";
import CareerText from "../Components/Layout/careersText";


import FirstSection from "../Projects/backgroundPro";
import SecondSection from "../Projects/Values";
import ThirdSection from "../Projects/Location";
import FourthSection from "../Projects/Portal";
import FifthSection from "../Projects/Gradient";
import SixthSection from "../Projects/number";
import SeventhSection from "../Projects/Client";
import EighthSection from "../Projects/Phone";
import TenthSection from "../Projects/secondBanner";
import NinthSection from "../Projects/webDesign";
import EleventhSection from "./Collage";
import FlyPages from "../Components/Layout/flyPages";

const Buster = () =>{
    const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
    const ValueBox = [
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
    ]
    
const  gradient = [
    {
        camera:"with ultra wide-angle camera"
    },
    {
        camera:"with the main camera"
    },
    {
        camera:"with the tele camera"
    },
  ]

    const secondText =
    {
        text1:"BusterBox",
       text2:"",

    }
    const collage = [
        {
            image:"../images/bus1.webp",
            class:"collage-img1"
        },
        {
            image:"../images/bus2.webp",
            class:"collage-img2"
        },
        {
            image:"../images/bus3.webp",
            class:"collage-img2"
        },
        {
            image:"../images/bus4.webp",
            class:"collage-img1"
        },
     ]
     const stats = [
        {
          number:"105%",
          title:"",
          description:"Increase in Email Revenue"
        },
        {
          number:"3%",
          title:"",
          description:"decrease in YOY Churn"
        },
        {
          number:"60%",
          title:"",
          description:"Increase in monthly active visitors"
        },
        {
          number:"60%",
          title:"",
          description:"Increase in Conversion Rate"
        },
     ]
     useEffect(() => {
      
        const handleResize = () => changeDeviceSize(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);
    
     const ref = useRef(null);
     const handleClick = () => {
    
      ref.current?.scrollIntoView({ behavior: "smooth" });
      
     };
     const branding = {
        branding:"Web design, development and performance marketing",
        title:"Re-engineering digital experiences & strategy for the #1 UK dog subscription box.",
        description:"We helped BusterBox streamline digital experiences across platforms and increase MRR by 30%. BusterBox came to Blackbird to improve the conversion rate. As such, our team focused on improving the design and user experience on the website, elevating branding and how BusterBox was generally presented to the potential customer, and implementing state-of-the-art technology to better track return on investment (ROI). - Boosted year-round email revenue as a percentage of total sales by 100%. - Lowered CPMs by 30% by introducing higher quality creatives to campaigns - Increase overall website conversion rate for the year by 60%"
   }
   const Expertice = [
    {
        title:"Expertise",
        des1:"Website Development",
        des2:"User Experience and Interface Design",
        des3:"Email marketing",
        des4:"Performance marketing"
    },
   ]
   const solution = {
    title:"Technology",
    description:"Blackbird also helped re-engineer the BusterBox digital experience to bring prospects new functionalities – including robust subscription features, cleaner user interfaces, and additional resources that allowed for the BusterBox brand to shine. These changes played a compounding effect that resulted in an increase of 60% in online conversions.    "
}
    return (
       <>
        <div className="container">

        <CareerText right={secondText.text1} left={secondText.text2}  link={secondText.link}/>
  

        </div>
        <FirstSection image="../images/buster.webp" mobile="../images/bustermobile.png"/>
         
        <SecondSection   item={Expertice} branding={branding.branding} title={branding.title} description={branding.description}/>

        <ThirdSection function={handleClick} video="../images/buster.mp4" class="buster-color"/>
        {/* <FourthSection/> */}
        {/* <FifthSection gradient={gradient}/> */}
        {/* <SixthSection/> */}

        <SeventhSection title="Process and Outcomes" description="When BusterBox approached Blackbird in 2021, they were having trouble maintaining an updated website, consistent creative supply, promotions and a clear-cut email marketing strategy. BusterBox was in need of more than just a visual uplift, they needed a consistent and integrated marketing strategy that would allow them to build their customer base to the next level.
    " stats={stats} video={"../images/buster.mp4"}/>

        {/* <EighthSection/> */}
        <NinthSection title="Email Marketing at Core" image="../images/bustercard.webp" description="For BusterBox, emails have always played an important role in managing the cost of acquisition. The benefit of having a powerful lead capture mechanism like the sign-up flow allowed them to turn emails into a cost-effective conversion tool that lowered the overall acquisition cost (CAC). 

However, the emails that were sent were neither visually stunning nor consistent. The capacities offered by Blackbird allowed this process to become more streamlined, with email creatives matching those of larger competitors at 2x the frequency. The result exceeded the clients expectation, as email sored to become the best converting channel for BusterBox.
"/>
        <div ref={ref}>
        <TenthSection secondImage="../images/buster.png" image="../images/busterbanner.png" class="buster-color" title={solution.title} description={solution.description}/>
        </div>
        <EleventhSection image={collage}/>
        <FlyPages class="buster-color"/>
        </>
    )
}

export default Buster;