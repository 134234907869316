import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/navigation";


// import required modules
import { EffectCreative,Navigation,Pagination } from "swiper";
import { AnimationOnScroll } from "react-animation-on-scroll";


const Testimonials = () => {

  const box = [
    {
      video:"../images/testimonial.mp4",
      founder:"../images/busCeo.png",
      company:"Gary Redmond",
      name:"Co-Founder, Buster Box",
      description:"The difference they made to our marketing and overall business was immediate and unbelievable. They took over all our web design as we did not have the skills to create our imagery, website and content. Black Bird Marketing streamlined our brand and made everything look more professional...",
      number:"105%",
      month:"Increase in Email Revenue",
      number2:"60%",
      month2:"Increase in monthly active visitors",
      key:"1"
    },
    {
      video:"../images/test2.mp4",
      founder:"../images/mibCeo.png",
      company:" Katharine Keane Barret",
      name:" Founder  MyIrelandBox",
      description:"It was just me and my husband working in the business in terms of marketing and  could not progress any further with our limited knowledge. As a valued member of our team that we wake up to daily with excitement for the projects ahead and new ideas. I cannot believe that we have found a marketing firm that is as passionate about our business as we are...",
      number:"48%",
      month:"increase in YOY Revenue",
      number2:"80%",
      month2:"increase in Emailv Revenue",
      key:"2"
    },

  ]
  function playPause(e) {
    e.preventDefault();
    var myVideo = document.getElementById("video1");
    if (myVideo.paused) myVideo.play();
    else myVideo.pause();
  }

    return (
<>

  <div className="container pb-100 testimonials">
 <h1 className="ptb-20">Testimonials</h1>

      <Swiper

      autoplay={true}
        grabCursor={true}
        // effect={"creative"}
        creativeEffect={{
          prev:{
            shadow: false,
            translate: ["-20%", 0, -1],
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
        pagination={{ clickable: true }}
        navigation={true}
        centeredSlides={true}   
        modules={[EffectCreative,Navigation,Pagination]}
        className="mySwiper3"
        
      >
             <AnimationOnScroll
               animateIn="animate__slower animate__fadeInUp"
              
               animateOnce
             >
        {box.map((item) => {
          return (
      
            <SwiperSlide className="first-testimonial"  key={item.key}>
            <div className="founder-video" >
            <video onclick="playPause()" id="video1" controls className="w-100"> 
                <source src={item.video} type="video/mp4" />
                </video>
            </div>

            <div className="founder-description">
              <div className="w-100">
                <img src={item.founder}/>
                  <div>
                        
                  <p><b>{item.company}</b></p>
                  <p>
                 {item.name}
                  </p>  
                  </div>
                 </div>

                 <p className="founder-text">{item.description}</p>

                    <div className="d-flex founder-number space-between">
                      <div>
                         <h2>{item.number}</h2>
                         <p>{item.month}</p>
                      </div>

                      <div>
                      <h2>{item.number2}</h2>
                         <p>{item.month2}</p>
                      </div>

                    </div>
            </div>
   
        </SwiperSlide>

          )
        })}
             </AnimationOnScroll>
    
      </Swiper>
 

  </div>
    
</>
    )
}

export default Testimonials;