// import axios from "axios";
// import { useEffect, useState } from "react";
import { Route, Routes,Switch } from "react-router-dom";

import About from "../pages/About";
import ScrollToTop from "../ScrollToTop";
import Error from "../pages/404.js";
import "animate.css";
import Homepage from "../pages/Home";
import Services from "../pages/Services";
import Careers from "../pages/Careers";
import ScrollToTopClick from "../scrollToTopClick";
import Steps from "../Components/Layout/steps";
import CleanFit from "../pages/cleanfit";
import Work from "../Components/Layout/Work";
import DevJob from "../pages/devjob";
import Finser from "../pages/Finser";
import Three from "../pages/Three";
import Buster from "../pages/buster";
import Formify from "../pages/formify";
import Ireland from "../pages/ireland";
import Intersport from "../pages/Intersport";
import Instinct from "../pages/instinct";
import Internship from "../Components/Layout/InternshipPage";
import BusterBoxLanding from "../pages/busterboxLanding";
import MibLanding from "../pages/MibLanding";






function RouteConfig(props) {



  return (
    <ScrollToTopClick>
   <ScrollToTop>

    <Routes>

      <Route path="/" element={<Homepage theme={props.theme}/>} />
      <Route path="/our-model" element={<About/>}/>
      <Route path="/services" element={<Services/>}/>
      <Route path="/careers" element={<Careers/>}/>
      <Route path="/steps" element={<Steps/>}/>
      <Route path="/cleanfit" element={<CleanFit/>}/>
      <Route path="/finser" element={<Finser/>}/>
      <Route path="/Three60" element={<Three/>}/>
      <Route path="/buster-box" element={<Buster/>}/>
      <Route path="/work" element={<Work/>}/>
      <Route path="/formify" element={<Formify/>}/>
      <Route path="/myireland-box" element={<Ireland/>}/>
      <Route path="/Intersport" element={<Intersport/>}/>
      <Route path="/instinct" element={<Instinct/>}/>
      <Route path="/Dveloper" element={<DevJob/>}/>
      <Route path="/*" element={<Error/>}/>
      {/* <Route path="/internship" element={<Internship/>}/> */}
      <Route path="/landing-page" element={<BusterBoxLanding/>}/>
      <Route path="/performance-powerhouse" element={<MibLanding/>}/>
    </Routes>

   </ScrollToTop>
   </ScrollToTopClick>
  );
}

export default RouteConfig;
