import React, { useState } from "react";

import emailjs from 'emailjs-com';
import StepsButton from "../Layout/stepsButton";
import StepHeading from "../Layout/stepsHeading";

const StepThree = (props) =>{
    const [message, setMessage] = useState("");
    const [information, setInformation] = useState(props.data.information);

    // const [message, setMessage] = useState("");
    // const [firstName, setFirstName] = useState(props.data.firstName);

    // const handleSubmit = (e) => {
    //   e.preventDefault();
    //   //if (validation(information)) props.next("information", information);
    //   if (validation(information)){
    //     const data = {
    //       "title":"Contact form" ,
    //       "status": "pending",
    //       "fields":
    //        {
    //            "full_name": props.data.info.firstName,
    //            "business_name": props.data.info.business,
    //            "email": props.data.info.email,
    //            "phone": props.data.info.phone,
    //            "information": information,
    //            "picked": props.data.picked
    //        }
    //      }
    //      fetch("https://cmsbbm.blackbird.marketing/wp-json/jwt-auth/v1/token", {
          
    //      method: "POST",
    //      headers: {
    //        Accept: "application/json",
    //        "Content-Type": "application/json",
    //      },

    //      body: JSON.stringify({
    //       "username": process.env.REACT_APP_WP_user,
    //       "password": process.env.REACT_APP_WP_userp
    //     }),
    //    }).then(async res => {
    //     const ress =  await res.json();
        
    //      fetch("https://cmsbbm.blackbird.marketing/wp-json/wp/v2/contactform", {
    //      method: "POST",
    //      headers: {
    //        Accept: "application/json",
    //        "Content-Type": "application/json",
    //        Authorization: "Bearer " + ress.token,
    //      },

    //      body: JSON.stringify(data),
    //    }).then(res => {
    //      console.log(document.getElementById("success-form"));
    //      document.getElementById("success-form").innerHTML = "You have successfully submitted the form!"
    //    }).catch(err => {
    //      console.log(err);
    //    })
    //    }).catch(err => {
    //      console.log(err);
    //    })
    //   }
    // };
  
    const firstNameChange = (e) => {
      validation(e.target.value);
      setInformation(e.target.value);
    };
      const validation = (val) => {
        var regex = /^[a-zA-Z0-9!@#$%^&*()-_=+{}\[\]:;"'<>,.?/\\| ]{1,100}$/;

        if (val.length === 0) {
          setMessage("");
          return false;
        } else if (!regex.test(val)) {
          setMessage("Enter a valid name!");
          return false;
        } else {
          setMessage("");
          return true;
        }
   
      };


    const heading = {
        title:"Additional Information",
        subTitle:"Help us understand your needs"
    }


    const sendEmail = () => {
      const templateParams = {
        to_email: props.data.info.email,
        from_name: props.data.info.firstName,
        message: information,
        picked: props.data.picked,
        phone:  props.data.info.phone,
        business_name:  props.data.info.business,
      };
  
      emailjs.send("service_9bvoxlb", "template_jis1ago", templateParams, "zOSJXf-zvBg0Thste")
        .then((response) => {
          console.log("Email sent successfully!", response);
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
    };


    const handleSubmit = (event) => {
      event.preventDefault();
      // Perform any validation if needed
      if (information.trim() === '') {
        setMessage('Please provide a brief overview of your requirements.');
      } else {
        setMessage('');
        // Call the sendEmail function here to send the email
        document.getElementById("success-form").innerHTML = "You have successfully submitted the form!"
        sendEmail();
      }
    };
  
    return (
     <>
        <div className="step-container  stepThree stepFour"  >
        <StepHeading heading={heading.title} title={heading.subTitle}/>
        <form onSubmit={handleSubmit}  name="radioForm">
           <div className="steps-height">
           <textarea name="textarea" onChange={firstNameChange} value={information}  placeholder="Please provide a quick overview / brief of your requirements."></textarea>
           {message ? <p className="error-msg stepone-error">{message}</p> : null}
           </div>
           <div >
          {/* <input type="submit" name="send" value="Complete Submission" /> */}
            <p className="ptb-20" id="success-form"></p> 
          </div>
           <StepsButton  prev={props.prev} value="Complete Submission" />
        </form>
     </div>
 
     </>
    )
}

export default StepThree;