import React, { useEffect, useRef, useState } from "react";
import CareerText from "../Components/Layout/careersText";


import FirstSection from "../Projects/backgroundPro";
import SecondSection from "../Projects/Values";
import ThirdSection from "../Projects/Location";
import FourthSection from "../Projects/Portal";
import FifthSection from "../Projects/Gradient";
import SixthSection from "../Projects/number";
import SeventhSection from "../Projects/Client";
import EighthSection from "../Projects/Phone";
import TenthSection from "../Projects/secondBanner";
import NinthSection from "../Projects/webDesign";
import EleventhSection from "./Collage";
import FlyPages from "../Components/Layout/flyPages";

const Ireland = () => {
    const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
    const ValueBox = [
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
    ]
    
const gradient = [
    {
        camera:"with ultra wide-angle camera"
    },
    {
        camera:"with the main camera"
    },
    {
        camera:"with the tele camera"
    },
  ]

    const secondText =
    {
        text1:"My ireland box",
       text2:"",

    }
    const collage = [
        {
            image:"../images/ire1.webp",
            class:"collage-img1"
        },
        {
            image:"../images/ire2.webp",
            class:"collage-img2"
        },
        {
            image:"../images/ire3.webp",
            class:"collage-img2"
        },
        {
            image:"../images/ire4.webp",
            class:"collage-img1"
        },
     ]
     const branding = {
        branding:"Web design, development and performance marketing",
        title:"Crafting a conversion-optimized platform and strategy for a subscription box that connects users to their Irish heritage. ",
        description:"We re-designed and re-developed My Ireland Box’s website to transform it from a static and visually basic platform into a feature packed driver of revenue. My Ireland Box’s transformation did not stop there; we also launched a new digital marketing strategy that continuously learns and adapts to market trends. This has seen MIB connect to a wider audience than ever before and move closer to fulfilling its vision."
   }
   const Expertice = [
    {
        title:"Expertise",
        des1:"Website Development",
        des2:"User Experience and Interface Design",
        des3:"Email marketing",
        des4:"Performance marketing"
    },
   ]
   const stats = [
    {
      number:"48%",
      title:"",
      description:"increase in YOY       Revenue "
    },
    {
      number:"80%",
      title:"",
      description:"increase in Emailv Revenue"
    },
 
 ]
 const solution = {
    title:"Capturing new customers at a high-rate",
    description:"Along with the re-development and re-design of the platform, Black Bird Marketing is engaged in crafting new email campaigns and digital ad campaigns, tailored to reach new audiences and capture previously untapped market share. Our holistic approach to performance marketing has seen My Ireland Box adopt a brand-new sales funnel and improve in virtually every key performance metric."
}

useEffect(() => {
      
    const handleResize = () => changeDeviceSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

 const ref = useRef(null);
 const handleClick = () => {

  ref.current?.scrollIntoView({ behavior: "smooth" });
  
 };
    return (
       <>
        <div className="container">
     
        <CareerText right={secondText.text1} left={secondText.text2}  link={secondText.link}/>
       
        </div>
        <FirstSection image="../images/ireland.webp" mobile="../images/irelandmobile.png"/>        
        <SecondSection   item={Expertice} branding={branding.branding} title={branding.title} description={branding.description}/>
        <ThirdSection function={handleClick} video="../images/irelandbox.mp4" class="green-color"/>
        <SeventhSection stats={stats} video="../images/irelandbox.mp4" title="Process and Outcomes" description="My Ireland Box approached Black Bird Marketing at the beginning of 2022 having reached the limit of their design and digital marketing skills as a subscription box start-up run by an ambitious couple - Katherine and Thomas.Our team engaged in several consultations with the client and each department completed a thorough audit and recommendations for what the brand was going to look like moving forward. They loved our recommendations and after months of work we launched the new-look www.myirelandbox.com - a new dynamic platform that enabled our client to focus on curating their product, while Black Bird Marketing took care of the rest.."/>
        <NinthSection image="../images/irelandcard.webp" title="Optimized for Conversion" description="We incorporated our proprietary sign-up flow to My Ireland Box’s website, resulting in immediate increase in lead-generation and conversion rates.The end result is a more fluid user experience that brings in measurable business results, previously thought unattainable. . Coupled with the modern and minimalist design of the website, the integrated conversion features have transformed My Ireland Box into the world’s leading subscription platform for curated Irish treats, literature, uniquely crafted pieces and more. "/>
        <div ref={ref}>
        <TenthSection secondImage="../images/mib.png" image="../images/irfix.webp" class="green-color" title={solution.title} description={solution.description}/>
        </div>
        <EleventhSection image={collage}/>
        <FlyPages class="green-color"/>
        </>
    )
}

export default Ireland;