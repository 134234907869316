import React from "react";
import { Link } from "react-router-dom";
import CareerText from "../Components/Layout/careersText";
import SpaceText from "../Components/Layout/SpaceText";

import { AnimationOnScroll } from "react-animation-on-scroll";
import FlyPages from "../Components/Layout/flyPages";
const Careers = () =>{
    
  
        const job = [
        
            // {
            //     position:"Senior Graphic Designer"
            // },
            // {
            //     position:"Senior Graphic Designer"
            // },
            // {
            //     position:"Senior Developer"
            // },
            // {
            //     position:"Senior Developer"
            // },
            // {
            //     position:"Senior Graphic Designer"
            // },
        ]

        const text =
        {
            text1:"JOB POSITIONS",
           text2:"Want to work in a beautiful office in Prishtina, Sofia, or Skopje? Check. Want to work with the latest technologies and the most talented people around you? Bingo. Want to benefit from flexible salary benefits and health benefits? Check. Don’t like working with assholes? We don’t hire them. Want to enjoy after-hour team events, PlayStation jams, and skiing in the mountains? That's us. Check our open positions below and apply now."
        }

        const secondText =
        {
            text1:"INTERNSHIP OPPORTUNITIES",
           text2:"Join the Black Bird Marketing team as an intern and gain hands-on experience in the field of digital marketing. Our agency specializes in helping businesses effectively reach their target audience through a variety of online channels, including social media, email marketing, search engine optimization, and paid advertising. As an intern, you will have the opportunity to work closely with our experienced team and contribute to ongoing client campaigns. This is a great opportunity for anyone looking to build their skills and knowledge in the fast-paced world of digital marketing. Apply now to take the first step in your career in this exciting industry",
        //    text:"Apply Now",
        //    link:"/internship"
        }

     
    return(
        
 <>
         <div className="pb-100">
            <img src="../images/careers.png" className="w-100"/>
            <div className="container careers pt-50">
                <h1 className="subTitle"> Work with us remotely or in office</h1>
                <CareerText right={text.text1} left={text.text2} />

                
                  
                    {job.length === 0 ?  <h1 className="vacancy"> Currently there is no open vacancy!   </h1> :<div className="careers-display pt-100 ">  {job.map((item)=>{
                    return (
                        <Link to="/Dveloper" key="1">
                        <AnimationOnScroll
                        animateIn="animate__slower animate__fadeInUp"
                      
                        animateOnce
                      >
                        <h1>{item.position}</h1>
                      <p>Apply Now</p>
                        </AnimationOnScroll>
                        </Link>
                    )
                 })}
                 
                 </div>
                 }
                   

                    {/* <div>
                        <h1>Senior Developer</h1>
                        <Link>Apply Now</Link>
                    </div> */}
               
                <CareerText right={secondText.text1} left={secondText.text2}  text={secondText.text} link={secondText.link}/>
            </div>
        </div>
        <FlyPages class="fly-pages"/></>
    )
}

export default Careers