import React, { useEffect, useRef, useState } from "react";
import CareerText from "../Components/Layout/careersText";


import FirstSection from "../Projects/backgroundPro";
import SecondSection from "../Projects/Values";
import ThirdSection from "../Projects/Location";
import FourthSection from "../Projects/Portal";
import FifthSection from "../Projects/Gradient";
import SixthSection from "../Projects/number";
import SeventhSection from "../Projects/Client";
import EighthSection from "../Projects/Phone";
import TenthSection from "../Projects/secondBanner";
import NinthSection from "../Projects/webDesign";
import EleventhSection from "./Collage";
import FlyPages from "../Components/Layout/flyPages";

const Instinct = () =>{
    const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
    const ValueBox = [
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
    ]
    
const  gradient = [
    {
        camera:"with ultra wide-angle camera"
    },
    {
        camera:"with the main camera"
    },
    {
        camera:"with the tele camera"
    },
  ]

    const secondText =
    {
        text1:"Instinct",
       text2:"social media influencers management platform ",

    }
    const collage = [
        {
            image:"../images/ins1.webp",
            class:"collage-img1"
        },
        {
            image:"../images/ins2.webp",
            class:"collage-img2"
        },
        {
            image:"../images/ins3.webp",
            class:"collage-img2"
        },
        {
            image:"../images/ins4.webp",
            class:"collage-img1"
        },
     ]
     useEffect(() => {
      
        const handleResize = () => changeDeviceSize(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);

     const ref = useRef(null);
     const handleClick = () => {
    
      ref.current?.scrollIntoView({ behavior: "smooth" });
      
     };

        
     const branding = {
        branding:"",
        title:"Building an award-winning social media influencers management platform in South-East Europe        ",
        description:"The founding team at Instinct reached out to BlackBird Marketing with a general idea of developing the first talent and influencer management platform in the region. This project saw Black Bird deploy our full-service model to maximum effect with our team being responsible for the business plan, go-to-market strategy, platform ideation, design and development as well as launching the final product."
   }
   const Expertice = [
    {
        title:"Expertise",
        des1:"Website Development",
        des2:"User Experience and Interface Design",
        des3:"Email marketing",
        des4:"Performance marketing"
    },
   ]
   const stats = [
    {
      number:"10k",
      title:"",
      description:"Registered Users in The First Month"
    },
    {
      number:"Received",
      title:"Innovation of the Year ",
      description:"Award in Q1 Laucn"
    },

   
 ]
    return (
       <>
        <div className="container">
       
        <CareerText right={secondText.text1} left={secondText.text2}  link={secondText.link}/>
     

        </div>
        <FirstSection image="../images/instinctbanner.webp" mobile="../images/instinctmobile.png"  />
        <SecondSection  item={Expertice} branding={branding.branding} title={branding.title} description={branding.description}/>  
       
        <ThirdSection function={handleClick} video="../images/instinct.mp4" class="instinct-color"/>
        {/* <FourthSection/> */}
        {/* <FifthSection gradient={gradient}/> */}
        {/* <SixthSection/> */}

        {/* <SeventhSection video={"../images/finser.mp4"}/> */}

        {/* <EighthSection/> */}
        <SeventhSection stats={stats} video="../images/instinct.mp4" title="Process and Outcomes" description="Black Bird engaged in several consultation sessions with the Instinct team in order to determine the features and functionalities that would turn the team’s vision into reality. The process saw us design and build our most complex technology architecture yet - we simultaneously developed three separate interfaces and databases that enable the platform to connect all three large user groups: the influencers, the Instinct intermediary and the hiring company. 

The end result is a ground-breaking platform that has solved social media influencer hiring hassles for numerous brands in the region. Furthermore, our work on the platform has been recognized with the “Innovation of the Year” award at the 2022 ICK ceremony.
"/>
        {/* <NinthSection image="../images/inscard.webp" description="Want to work in a beautiful office in Prishtina, Sofia, or Skopje? Check. Want to work with the latest technologies and the most talented people around you?"/> */}
        <div ref={ref}>
        <TenthSection secondImage="../images/instinct.png" title="Generating Buzz Pre-Launch" description="Black Bird marketing also designed key aspects of the go-to-market strategy with our team implementing several groundbreaking features prior to launch of the platform. The main initiative in this regard was developing an in-app game that generated plenty of buzz for the platform prior to launch. The game saw users complete a one-time obstacle challenge and encouraged them to invite friends to compete for prizes in regional leaderboards. " image="../images/instfixed.webp" class="instinct-color"/>
        </div>
        <EleventhSection image={collage}/>
        <FlyPages class="instinct-color"/>
        </>
    )
}

export default Instinct;