
import {createContext, React,useEffect,useRef, useState} from "react";
import "./App.css";
import {  useLocation } from "react-router-dom";
import Nav from "./Components/Layout/Nav";

import RouteConfig from "./Router";
import Footer from "./Components/Layout/Footer";
import Footer2 from "./Components/Layout/Footer2";
// export const ThemeContext = createContext(null)

function App() {
  const location = useLocation();
// const [theme , setTheme] = useState("light")
//   console.warn = console.error = () => {};
//   const ToggleTheme = () => {
//     setTheme((curr)=> (curr === "light" ? "dark" : "light"))
//   }
  return (
   <>
   {location.pathname !== "/landing-page" && location.pathname !== "/performance-powerhouse" && (
        <>
          {" "}
    
          <Nav />
          
        </>
      )}
       

       <RouteConfig />
    
        <Footer2/>
 
   </>
  );
}

export default App;
