import React from "react";
import JobPosition from "../Components/Layout/JobPosition";

const DevJob = () => {

    return (
            <div className="container">
               <JobPosition/>
            </div>
    )
}

export default DevJob;