import React from "react";

const StepHeading = (props) => {
    return (
       <div>
        <p>{props.heading}</p>
        <h1 className=" stepsTitle">{props.title}</h1>
       </div>
    )
}

export default StepHeading;