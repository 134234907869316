import React from "react";
import ScheduleButton from "./scheduleButton";


const Schedule = (props) =>{

    return (
       <>
         <div className="landing-description pt-100">
          <h1>
             We <span>Specialize</span> In Scaling Subscription Box Business <span>Profitably</span>
          </h1>
      
          <p className="ptb-20">Guaranteed to beat your existing ad KPI's (ROAS, Ad Profit, CAC, etc) by at least 25% in under 90 days or you don’t pay</p>
            </div>
            <div className="landing-average pb-100">
                <h1>IN THE FIRST 90 DAYS OUR AVERAGE PARTNER (NOT OUR BEST):</h1>
                <div className="average-display ptb-20">
                {props.stats.map((item) => {
                    return (
                        <div className="average-stats">
                        <p>{item.description}</p>
                        <h2>{item.number}</h2>
                        <img src={item.image}/>
                    </div>
                    )
                })}
                </div>
              <ScheduleButton/>
            </div>
            </>
    )
}
export default Schedule;