import React, { useEffect, useRef, useState } from "react";
import CareerText from "../Components/Layout/careersText";


import FirstSection from "../Projects/backgroundPro";
import SecondSection from "../Projects/Values";
import ThirdSection from "../Projects/Location";
import FourthSection from "../Projects/Portal";
import FifthSection from "../Projects/Gradient";
import SixthSection from "../Projects/number";
import SeventhSection from "../Projects/Client";
import EighthSection from "../Projects/Phone";
import TenthSection from "../Projects/secondBanner";
import NinthSection from "../Projects/webDesign";
import EleventhSection from "./Collage";
import FlyPages from "../Components/Layout/flyPages";

const Finser = () =>{
    const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
    const ValueBox = [
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
    ]
    
const  gradient = [
    {
        camera:"with ultra wide-angle camera"
    },
    {
        camera:"with the main camera"
    },
    {
        camera:"with the tele camera"
    },
  ]

    const secondText =
    {
        text1:"Finser",
       text2:"Branding project for an innovative fintech solution",

    }
    const collage = [
        {
            image:"../images/fins1.png",
            class:"collage-img1"
        },
        {
            image:"../images/fins2.png",
            class:"collage-img2"
        },
        {
            image:"../images/fins3.png",
            class:"collage-img2"
        },
        {
            image:"../images/fins4.png",
            class:"collage-img1"
        },
     ]
     const branding = {
          branding:"Branding",
          title:"Create a brand that communicates competence and innovation.",
          description:"We designed the look and feel of both, digital and physical assets, of Finser - one of the first digital payment solution providers in Kosovo. Since then, Finser has launched multiple service points across Kosovo and expanded its product portfolio to become the one-stop solution for all digital payments and transfers. "
     }
     const Expertice = [
        {
            title:"Expertise",
            des1:"Creative Services",
            des2:"Print Design",
            des3:"Branding"
        },
     
     ]
     const stats = [
        {
          number:"22",
          title:" branches",
          description:"Opened across 12 major cities in Kosovo, unified under one visually consistent brand"
        },
 
   
     ]
     useEffect(() => {
      
        const handleResize = () => changeDeviceSize(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);
    
     const ref = useRef(null);
     const handleClick = () => {
    
      ref.current?.scrollIntoView({ behavior: "smooth" });
      
     };
    return (
       <>
        <div className="container">
    
        <CareerText right={secondText.text1} left={secondText.text2}  link={secondText.link}/>
     

        </div>
        <FirstSection image="../images/finserBannerr.png" mobile="../images/finsermobile.png"/>
         
        <SecondSection   item={Expertice} branding={branding.branding} title={branding.title} description={branding.description}/>
        <ThirdSection function={handleClick} video="../images/finser.mp4" class="finser-color"/>
        {/* <FourthSection/> */}
        {/* <FifthSection gradient={gradient}/> */}
        {/* <SixthSection/> */}

        <SeventhSection title="Why Finser trusts Blackbird"  description="After initial consultations, the Blackbird team embarked on a month-long research and development phase and came back with several proposals on the visual identity. Finser particularly appreciated the team’s consideration of how the new brand would align to the firms’ ambitious go to market strategy" stats={stats} video={"../images/finser.mp4"}/>

        {/* <EighthSection/> */}
        <NinthSection title="The Challenge" image="../images/finsercard.png" description="Finser’s ambitious business vision to introduce a rapidly growing global trend to the more traditional financial sector in Kosovo posed several intriguing challenges. First, the brand needed to communicate trust as it’s success depended on people shifting their attitude from cash-based transaction customs to new ways of doing business and payments- all digital."/>
        <div ref={ref}>
        <TenthSection secondImage="../images/finser.png" image="../images/finsersecbanner.png" class="finser-color" title="The Solution" description="We took a step back and looked at the financial system as a whole - and its 4 main stakeholders and actors. We then shifted focus on how Finser would contribute in facilitating and improving the interaction between these 4 main pillars of the financial system. The end result was a highly versatile brand that established Finser as a serious and trustworthy player in the financial system. Furthermore, the brand could be easily adapted for multiple use - from website design, to Point-of-Sale branding, promotional material as well as traditional media assets."/>
        </div>
        <EleventhSection image={collage}/>

        <FlyPages class="finser-color"/>
        </>
    )
}

export default Finser;