import React from "react";
import ScheduleButton from "./scheduleButton";

const LandingDescription = (props) => {
    return (
        <div className="landing-content pt-50 pb-100">
            <h1>{props.title}</h1>
            <p className="ptb-20">{props.firstParagraph}</p>
            <p >{props.secondParagraph}</p>
             <ScheduleButton/>
        </div>
    )
}

export default LandingDescription;