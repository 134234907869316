import React from "react";
import Different from "../Components/Layout/different";
import Team from "../Components/Layout/Team";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Testimonials from "../Components/Layout/testimonial";

import ModelBox from "../Components/Layout/modelBox";
import FlyPages from "../Components/Layout/flyPages";
const About =()=>{
    
    const box = [
        {
            image:"../images/box.png",
            description:"Modern strategy is driven by simplicity. We place great emphasis in creating content and strategy that is adjusted for the modern consumer"
        },
        {
            image:"../images/box.png",
            description:"We put customers first. Everything else flows from there. We dedicate alot of time and effort in building lasting relationships with all of our customers."
        },
        {
            image:"../images/box.png",
            description:"Clients value our opinion and trust our judgment. Our job is to guide the client towards strategic growth."
        },
    ]

       const image = [
        {
            image1:"../images/art1.png",
            image2:"../images/art2.png",
            name:"Art Raifi",
            position:"Founder & CEO"
        },
        {
            image1:"../images/bon.png",
            image2:"../images/bon.png",
            name:"Bonart Ajvazi",
            position:"Co-Founder & COO"
        },
        {
            image1:"../images/robert1.png",
            image2:"../images/robert2.png",
            name:"Robert Ejupi",
            position:"Managing Partner & CFO"
        },

   
        {
            image1:"../images/LEKA.png",
            image1:"../images/LEKA.png",
   
            name:"Lekë Sadiku",
            position:"Managing Partner & Head of Business Development"
        },
        {
            image1:"../images/gango1.png",
            image2:"../images/gango2.png",
            name:"Agan Haziri",
            position:"Full Stack Developer"
        },
        {
            image1:"../images/pllum1.png",
            image2:"../images/pllum2.png",
            name:"Pëllumb Sadiku",
            position:"Full Stack Developer"
        },
        {
            image1:"../images/sh1.png",
            image2:"../images/sh2.png",
            name:"Shpat Mustafa",
            position:"Front End Developer"
        },
        {
            image1:"../images/il1.png",
            image2:"../images/il2.png",
            name:"Ilirian Sadiku",
            position:"Senior UI/UX Designer"
        },

        {
            image1:"../images/dion1.png",
            image2:"../images/dion2.png",
            name:"Dion Murati",
            position:" Lead Graphic Designer"
        },
 
        {
            image1:"../images/fuf.png",
            image2:"../images/fuf.png",
            name:"Fjolla Sopjani",
            position:" Senior Graphic Designer"
        },
        {
            image1:"../images/daf1.png",
            image2:"../images/daf2.png",
            name:"Dafina Bunjaku",
            position:" Graphic Designer Intern"
        },
      
        {
            image1:"../images/ben1.png",
            image2:"../images/ben2.png",
            name:"Ben Raifi",
            position:"Business Development Representative"
        },
        {
            image1:"../images/zan.png",
            image2:"../images/zan.png",
            name:"Zanna Krasniqi",
            position:"Content Writer Apprentice"
        },
        {
            image1:"../images/xona.png",
            image2:"../images/xona.png",
            name:"Egzona Loshaj",
            position:"Social Media Intern"
        },
        // {
        //     image1:"../images/shac.png",
        //     image2:"../images/bon.png",
        // },
        // {
        //     image1:"../images/flor.png",
        //     image2:"../images/shrek.jpg",
        // }
       ]
    return(
<>
    
<div className="container about">
                <AnimationOnScroll
        animateIn="animate__slower animate__fadeIn"
      
        animateOnce
      >
                 <h1 className="title pt-100 pb-50">Our <span className="model-color">full-service</span> model is tailored to achieve measurable results</h1></AnimationOnScroll>
                 <AnimationOnScroll
        animateIn="animate__slower animate__fadeIn"
      
        animateOnce
      >
                 <div className="d-flex space-between aboutus-description">
                   
                    <div className="w-49"><img src="../images/aboutbanner.png" className="w-100 about-banner"/></div>
                    <div className="w-49">
                   <p>Black Bird Marketing creates powerful digital marketing campaigns and strategies through a proprietary blend of data-driven methods, highly-skilled human capital, dedicated customer service and a growth-oriented culture</p>

    
                   </div>
                 </div>
                 </AnimationOnScroll>
       
        <ModelBox/>
        <AnimationOnScroll
        animateIn="animate__slower animate__fadeInUp"
      
        animateOnce
      >
      
        <div className="pt-100 pb-100">
            <h1 className="smallTitle w-25">Birds of a feather, flock together!</h1>
           <p className="w-25 ptb-20">We introduce the team that makes Black Bird Marketing your perfect digital partner.</p>
           <Team team={image}/>
        </div>
        </AnimationOnScroll>
       
              </div>
               <FlyPages class="fly-pages"/></>
    )
}

export default About;