import React from "react";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';


const FourthSection = () => {
    return (
          <div className="container  portal">
          <ReactCompareSlider
           itemOne={<ReactCompareSliderImage src="../images/cleannew.png" srcSet="../images/cleannew.png"  />}
           itemTwo={<ReactCompareSliderImage src="../images/oldclean.png" srcSet="../images/oldclean.png"/>}
             />
          </div>

    )
}

export default FourthSection;