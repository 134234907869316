import React, { useState } from "react";
import StepsButton from "../Layout/stepsButton";
import StepHeading from "../Layout/stepsHeading";

const StepOne = (props) =>{

    const heading = {
        title:"Get in touch",
        subTitle:"You are about take off. Check-in."
    }



    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const [firstName, setFirstName] = useState(props.data.firstName);
    const [business, setBusiness] = useState(props.data.business);
    const [email, setEmail] = useState(props.data.email);
    const [phone, setPhone] = useState(props.data.phone);
  
    const handleSubmit = (e) => {
      e.preventDefault();
      console.log(validation(firstName))
      console.log(validationEmail(email))
      if (validation(firstName) && validationEmail(email)){
      const info  = {
        firstName: firstName,
        email: email,
        business: business,
        phone: phone
      }
      props.next("info", info); 
      //props.next("firstName", firstName); 
      
      }
   
    };
  
    const firstNameChange = (e) => {
      validation(e.target.value);
      setFirstName(e.target.value);
    };
    const firstEmailChange = (e) => {
        validationEmail(e.target.value);
        setEmail(e.target.value);
      };
  
    const validation = (val) => {
      var regex = /^[a-zA-Z- ]{1,30}$/;
    
      if (val.length === 0) {
        setMessage("");
        return false;
      } else if (!regex.test(val)) {
        setMessage("Enter a valid name!");
        return false;
      } else {
        setMessage("");
        return true;
      }
    };
    const validationEmail = (val) => {
      var email = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    
        if (val.length === 0) {
          setError("");
          return false; 
        } else if (!email.test(val)) {
          setError("Please enter a valid email address");
          return false;
        } else {
          setError("");
          return true;
        }
      };
    return (
        <>
           <div className="step-container  stepOne steps-height">
           <StepHeading heading={heading.title} title={heading.subTitle}/>
           <form onSubmit={handleSubmit}>
          <div className="steps-height">
          <div className="stepone-display ">
             <input type="text" name="name" placeholder="Your Full Name*"           value={firstName}
          onChange={firstNameChange} required></input>{message.length !== 0 ? <p className="email-error">{message}</p> : null}
             <input type="text" name="business" placeholder="Business Name*" onChange={(e) => {setBusiness(e.target.value)}}></input>
             <input type="email" name="email" placeholder="E-mail Address*"       value={email}
          onChange={firstEmailChange} required/>
{error.length !== 0 ? <p className="email-error1">{error}</p> : null}


             <input type="text" name="phone" placeholder="Phone Number (optional)"  onChange={(e) => {setPhone(e.target.value)}}></input>   
            </div>      
          </div>

            <StepsButton class="stepone-prev" display="steps-end" value="Next Step"/>  
           </form>
        </div>
       
        </>
    )
}

export default StepOne;