import React from "react";
import WebBox from "../Components/Layout/Web";

import { AnimationOnScroll } from "react-animation-on-scroll";
import SpaceText from "../Components/Layout/SpaceText";
import ApproachBox from "../Components/Layout/AproachBox";

const Services = ()=>{
    const text =
        {
            text1:"Approach",
           text2:"We are Black Bird Marketing. We partner with brave clients to build brand experiences people love."
        }
    return (
<>
      <div className="container service">
            <div className="d-flex  pt-100">
                   
            <AnimationOnScroll
                            animateIn="animate__slower animate__fadeInUp"
                          className="w-49"
                            animateOnce
                          >
                <h1 className="title ">What we do</h1>     
                 </AnimationOnScroll>
                 
                <div className="w-60">
                    <h1 className="smallTitle">We create effective strategies, powerful identities, seamless platforms, and memorable experiences to connect people to brands and organizations.</h1>
                    <AnimationOnScroll
                            animateIn="animate__slower animate__fadeIn"
                   
                            animateOnce
                          >
                    <div className="d-flex space-between pt-50">

                        <div className=" service-box">
                            <div className="ptb-20">
                            <img src="../images/content.svg"></img>
                            <span>Content</span></div>
                            <p>We put customers first. Everything else flows from there. We dedicate alot of time and effort in building lasting relationships with all of our customers.</p>
                            </div>

                        <div className="service-box">
                            <div className="ptb-20">
                            <img src="../images/smm.svg"></img>
                            <span>Social Media Marketing</span></div>
                            <p>We put customers first. Everything else flows from there. We dedicate alot of time and effort in building lasting relationships with all of our customers.</p>
                        </div>
                    </div>
                    </AnimationOnScroll>
                </div>
            </div>

  
        </div>
        {/* <div className="service-webbox pt-100">
           <div className="container">
           <AnimationOnScroll
                            animateIn="animate__slower animate__fadeInUp"
                   
                            animateOnce
                          >
           <h1 className="subTitle">We are Black Bird Marketing. We partner with brave clients to build brand experiences people love.
                 Bold work requires a bold approach and an attitude that is anything but regular.</h1>    </AnimationOnScroll>
        
            <WebBox/>
           </div>
            </div> */}
                  <div className="container">
                       <AnimationOnScroll
               animateIn="animate__slower animate__fadeInUp"
             
               animateOnce
             >
    
            <SpaceText right={text.text1} left={text.text2}/>
            </AnimationOnScroll>
            <ApproachBox/>
        </div>
        </>
    )
}
export default Services;