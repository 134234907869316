import React from "react";
import { Link } from "react-router-dom";

const Button =(props)=>{
    return (
      <Link  to={props.link}>
        <div className={`fly-button ${props.class}`} >

        {props.text}
        </div>
        </Link>
    )
}

export default Button ;