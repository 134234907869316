import React from "react";
import { Link } from "react-router-dom";
import { AnimationOnScroll } from "react-animation-on-scroll";
const ApproachBox = () => {
    const Box = [
        {
            title:"Growth Marketing",
            subTtile:"We partner with ambitious and exciting businesses to fuel their growth and scale efficiently across their digital platforms",
            list:"Tailored growth strategy, E-mail marketing and retention optimization, Digital Media Buying and optimization, Data Analytics; A/B Testing, Reporting and Insights",
            video:"../images/STATISTIKAT.mp4"
            // video:"../images/360.mp4"zs

        },
            
        {
            title:"Digital Product Development",
            subTtile:"We create custom technology solutions that streamline our client’s business, making them stand out from the competition, be more efficient and focus on conversion.",
           list:"Website design & Development, User Interface and Experience Design, Wordpress and Woocommerce, Fully-Custom Digital platform",
            video:"../images/BBMWEB.mp4"
        },
        {
            title:"Branding & Design",
            subTtile:" We create memorable and impactful brand identities and tell our clients’ stories through powerful design work ",
        list:"Brand & Identity Design, Cross-Platform digital content design",
            video:"../images/360.mp4"

        },

     
     ]


    return (
  
        <div className="approach-box pt-100 pb-100">
    
            {Box.map((item) => 
              <AnimationOnScroll
              animateIn="animate__slower animate__fadeInUp"
              key={item.title}
              animateOnce
            >
             <>
             <video autoPlay loop muted playsInline className="w-100"> 
                <source src={item.video} type="video/mp4" />
                </video>
                {/* <img src={item.video}/> */}
                <div>
                <p> <b>{item.title}</b></p>
                <p className="pb-20">{item.subTtile}</p>
                <p className="sub-text-list">{item.list}</p>
            </div>
             </>
             </AnimationOnScroll>
            )}
        </div>
       
    )
}

export default ApproachBox;