import React, { useEffect, useRef, useState } from "react";
import CareerText from "../Components/Layout/careersText";


import FirstSection from "../Projects/backgroundPro";
import SecondSection from "../Projects/Values";
import ThirdSection from "../Projects/Location";
import SeventhSection from "../Projects/Client";
import TenthSection from "../Projects/secondBanner";
import NinthSection from "../Projects/webDesign";
import EleventhSection from "./Collage";
import FlyPages from "../Components/Layout/flyPages";
import SixthSection from "../Projects/number";

const Intersport = () =>{
    const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
    const ValueBox = [
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
    ]
    
const  gradient = [
    {
        camera:"with ultra wide-angle camera"
    },
    {
        camera:"with the main camera"
    },
    {
        camera:"with the tele camera"
    },
  ]

    const secondText =
    {
        text1:"Intersport",
     

    }
    const collage = [
        {
            image:"../images/int1.webp",
            class:"collage-img1"
        },
        {
            image:"../images/int2.webp",
            class:"collage-img2"
        },
        {
            image:"../images/int3.webp",
            class:"collage-img2"
        },
        {
            image:"../images/int4.webp",
            class:"collage-img1"
        },
     ]
    
    
     const branding = {
        branding:"Web design, development and performance marketing",
        title:"Enabling the leading sportswear retailer to translate their core competencies to the online realm and to dominate e-commerce",
        description:"We enabled INTERSPORT to increase ecommerce conversion by 75% and re-engineered their digital experience. INTERSPORT is the world’s leading sportswear retail franchise, on a mission to be the ultimate one-stop solution for everyone’s sporting and lifestyle needs. The global giant first launched its digital operations in Kosovo in 2018 but they’re platform soon reached its limits and they needed an upgrade. That's when Black Bird Marketing was engaged to build a new platform and tailor a new digital marketing strategy to become one of the country’s most recognizable and trusted brands."
   }
   const Expertice = [
    {
        title:"Expertise",
        des1:"Website Development",
        des2:"User Experience and Interface Design",
        des3:"Email marketing",
        des4:"Performance marketing"
    },
   ]
   
   const stats = [
      {
        number:"45%",
        title:"",
        description:"increase in YOY       revenue "
      },
      {
        number:"19%",
        title:"",
        description:"decrease in cost-per-purchase"
      },
      {
        number:"92%",
        title:"",
        description:"increase in YOY conversion rate"
      },
      {
        number:"36%",
        title:"",
        description:"increase to ROMI"
      },
   ]
   const solution = {
           title:"Design that performs online as well as in-store",
           description:"The main value add of Black Bird Marketing’s engagement for INTERSPORT, is our ability to integrate our digital marketing insights and design powerful print and POS campaigns that drive revenue across INTERSPORT’s vast retail footprint. Further, we are now working on implementing cutting edge omnichannel services for our client, including Click-and-Collect and an advanced mobile application that will blur the lines between a user’s in-store and ecommerce experience."
   }
   
   useEffect(() => {
      
    const handleResize = () => changeDeviceSize(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

 const ref = useRef(null);
 const handleClick = () => {

  ref.current?.scrollIntoView({ behavior: "smooth" });
  
 };
    return (
        
       <>
        <div className="container">

        <CareerText right={secondText.text1} left={secondText.text2}  link={secondText.link}/>
 
        </div>
        <FirstSection image="../images/intersport.webp" mobile="../images/intersportmobile.png"/>
        <SecondSection  item={Expertice} branding={branding.branding} title={branding.title} description={branding.description}/>  
      
        <ThirdSection function={handleClick} video="../images/int.mp4" class="inter-color" />
        <SeventhSection stats={stats} video="../images/int.mp4" title="Process and Outcomes" description="The digital product development team at Black Bird Marketing sat down with the client and over an eight-week cycle mapped out the architecture and features that would be incorporated into a high-performance ecommerce platform. Since launch the website is visited by thousands of users daily , with traffic exceeding 1 million unique visitors for the year. But an increase in traffic is only the first step to our holistic digital marketing strategy for Intersport. Their performance metrics tell that story:"/>
        <NinthSection image="../images/intcard.webp" title="Cutting-edge conversion features" description="INTERSPORT’s ecommerce platform is packed with rich features that make for an excellent user experience and increase conversion. First, the website features an advanced up-selling and cross-selling recommendation system that displays the most relevant products to the right users. Next, the platform is seamlessly integrated with INTERSPORT’s retail CRM allowing for automated product import, stock level updates as well as real-time sales analytics. Finally, the website’s structure allows for quick and easy landing page creation that increases the efficiency of monthly marketing campaigns."/>
        <div ref={ref}>
        <TenthSection secondImage="../images/smallint.png" image="../images/intfixed.webp" ref={ref} title={solution.title} description={solution.description}/>
        </div>
        <EleventhSection image={collage}/>
        <FlyPages class="inter-color "/>
        </>
    )
}

export default Intersport;