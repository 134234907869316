import React, { useState } from "react";
import StepFour from "../Steps/stepFour";
import StepOne from "../Steps/stepOne";
import StepThree from "../Steps/stepThree";
import StepTwo from "../Steps/stepTwo";
import { GoCheck } from "react-icons/go";

import { Link } from "react-router-dom";
const Steps = () => {
    const  [step,setStep] = useState(0)

    const [data, setData] = useState({
        info: {
          firstName: "",
          business:"",
          email:"",
          phone:"",
        },
        picked: "",
        information: "",
      });
  console.log("ddd",data)
    const handlePrevStep = () => {

        setStep(step - 1);
      };
    

      const handleNextStep = (name, value, final = false) => {
        setData((prev) => ({ ...prev, [name]: value }));

        setStep((prev) => prev + 1);
      };
    

    const stepsOption = [       
        <StepOne
          next={handleNextStep}
          data={data}
        />,
        <StepTwo next={handleNextStep} data={data}  prev={handlePrevStep}/>,
        <StepThree
          next={handleNextStep}
          prev={handlePrevStep}
          data={data}
     
        />,
        // <StepFour
        //   next={handleNextStep}
        //   prev={handlePrevStep}
        //   data={data}
       
        // />,
  
      ];

      const checkNextStepAvailability = (stepNumber) => {
        switch (stepNumber) {
          case 1:
            if (data.info.firstName.length > 0) return true;
            return false;
          case 2:
            if (data.info.firstName.length > 0 && data.picked.length > 0) return true;
            return false;
          case 3:
            if (
              data.info.firstName.length > 0 &&
              data.picked.length > 0 &&
              data.information.length > 0
            )
              return true;
            return false;
          // case 4:
          //   if (
          //     data.info.firstName.length > 0 &&
          //     data.picked.length > 0 &&
          //     data.information.length > 0 &&
          //     data.email.length > 0
          //   )
          //     return true;
          //   return false;
       
          default:
            return false;
        }
        return true;
      };
    

    return (
        <div className="container">
  <div className=" steps">
  <ul>
          
          <div className="steps-display">
          <li
            onClick={() => setStep(0)}
            className={` ${ step > 0 ? "active_step" : step === 0 ? "active_step" : "newclass"}`}
          >
                    <p className="step-circle">1 </p>
        
          </li>
          <p>Step 1</p>
          </div>
          <div className="progress-line"></div>

         <div className="steps-display">
         <li
            onClick={() => (checkNextStepAvailability(1) ? setStep(1) : null)}
            className={`${ step > 1 ? "active_step" :
              step === 1 ? "active_step" : step > 1 ? "newclass" : ""
            }`}
          >
      
              <p className="step-circle">2 </p>
        
          </li>
          <p>Step 2</p>
         </div>
          <div className="progress-line"></div>

          <div className="steps-display">
          <li
            onClick={() => (checkNextStepAvailability(2) ? setStep(2) : null)}
            className={`${step > 2 ? "active_step" :
              step === 2 ? "active_step" : step > 2 ? "newclass" : ""
            }`}
          >
    
              <p className="step-circle">3 </p>
    
          </li>
          <p>Step 3</p>
          </div>
          {/* <div className="progress-line"></div> */}

        {/* <div className="steps-display">
        <li
            onClick={() => (checkNextStepAvailability(3) ? setStep(3) : null)}
            className={`${
              step === 3 ? "active_step" : step > 3 ? "newclass" : ""
            }`}
          >
   
              <p className="step-circle">4</p>
        
          </li>
          <p>Step 4</p>
        </div> */}
  
        </ul>
     

        <div>{stepsOption[step]}</div>
         
  </div>
        </div>
    )
}


export default Steps;