import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
const Team = (props) =>{
    return(
        <>
        <div className="team-display pt-50">
              {props.team.map((item)=>{
                return (
                    <AnimationOnScroll
                    animateIn="animate__slower animate__fadeInUp"
                    key={item.name}
                    animateOnce
                  >
                    <img src={item.image1} className="team-hover"/>
                    <img src={item.image2} className="team-absolute"/>
                    <div className="team-text">
                        <h1>{item.name}</h1>
                    </div>
                    <p>{item.position}</p>   
                    </AnimationOnScroll>
                )
              })}

        </div>
        </>
    )
}

export default Team;