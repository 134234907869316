import React from "react";
import ScheduleButton from "./scheduleButton";

const Case = (props) => {
    return (
        <div className="case d-flex">

            <div className="case-result">
                <h2>{props.ad.heading}</h2>
                <video onclick="playPause()" id="video1" controls className="w-100"> 
                <source src={props.ad.video} type="video/mp4" />
                </video>
                <p className="ptb-20">{props.ad.firstParagraph}</p>
                <h1 >Results</h1>
                <p className="ptb-20">{props.ad.secondParagraph}</p>
                <img src={props.ad.image} className="w-100"/>
                <ScheduleButton/>
            </div>

            <div className="case-client">
                <img src={props.image} />
                <div className="case-display">
                    {props.category.map((item) => {
                        return (
                            <div className="client-category">
                            <h2>{item.category}</h2>
                            <p>{item.name}</p>
                        </div>
                        )
                    })}
           

       
                </div>
              
            </div>

        </div>
    )
}

export default Case;