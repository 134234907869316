import React from "react";
import { Link } from "react-router-dom";
import Button from "../Components/button";

const Error = ()=>{

    return(
        <div className="Error container pt-100 pb-100 ">
             <div> 
                <img src="../images/404.svg" className="w-100"/>
             </div>
             <h1 className="subTitle ptb-20">Page not found</h1>
             <div>
                {/* <Link to="/">Let's Fly</Link> */}

                <Button text={"Get Back"} class={"error-btn"} link={"/"}/>
            </div>
        </div>
    )
}

export default Error;