import React from "react";

const Expert = () => {
    return (
        <div className="container">
             <div className="expert  pb-100">
            <div className="w-100">
                <div>  
                 <h1>Meet Your Expert:</h1>
                 <h2>Art Raifi</h2>
                 <p className="ptb-20">I started my career in paid digital marketing and design in 2010 building out my very first online business as a YouTuber, safe to say I was ahead of the market over 13 years ago.
                 </p>
                </div>
                 <img src="../images/expert.png" />
            </div>
            <p className="pb-20">Fast forward to my university years I started a social media agency working for close friends and family. This was my first full-time intro to Facebook and Google ads, and spending the first few Euros & Dollars in marketing was a true eye-opener, I know what it's like to NEED a specific profit margin - I have been asked this for years on end.
In 2019 I began my Master’s in Business Administration at Brandeis University in Massachusetts while,  continued running the business (that had grown to a headcount of 10). Among others, I understood what it takes to run a successful business, read up countless case studies and began to apply those learning across clients.
A year later I decided to transform my social media agency into a full-digital powerhouse that uses data as a means of powering growth for small and medium businesses around the world.
Long story short, I have been in the paid digital marketing industry for over a decade and in that time, I have managed dozens of brands, developed countless software solutions, and led my agency from a small-scale social media company to a performance powerhouse that specializes in direct-to-consumer brands. I have seen it all and now I leverage my learnings, connections, and secrets of these high-growth brands to help scale your brand.</p>
        </div>
        </div>
    )
}

export default Expert;