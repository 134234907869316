import React from "react";


const SecondSection = (props) => {
    return (
        <div className="container value pt-50">
            <div className="w-49">
                <p className="brand-color">{props.branding}</p>
                <h1 className="smallTitle">{props.title}</h1>
                <p className="ptb-20"> {props.description}</p>
            </div>
             <div className="value-display">
               {props.item.map((item)=>{
                return (
                    <div key={item.title}>
                    <h2 className="ptb-20">{item.title}</h2>
                     <p >{item.des1}</p>
                     <p >{item.des2}</p>
                     <p >{item.des3}</p>
                     <p >{item.des4}</p>
                    </div>
                )
               })}
             </div>
        </div>
    )
}

export default SecondSection;