import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const EleventhSection = (props) => {
    return (
       <div className="pt-100">
       <div className="container">
       <h1 className="ptb-20 collage-header subTitle">The Brand Visualized</h1>
       </div>
         <div className="collage">
          {props.image.map((item) => {
            return (
              <LazyLoadImage src={item.image} className={item.class}  /> 
            )
          })}


        </div>
       </div>
    )


}

export default EleventhSection;