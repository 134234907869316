import React from "react";
import {BsArrowDown} from "react-icons/bs"
const ThirdSection = (props) =>{

   console.log(props.function)
    return (
        <div className="container pt-100 pb-100">
            <div className={`send-duration pt-100 ${props.class}`}>
               <div className="d-flex sub-container ">
                <div  className="w-49"   onClick={props.function}>
                  <div>
                  <h1>01</h1>
                <p>Background</p>
                  </div>
                <BsArrowDown/>  
                </div>

                <div  className="w-49" onClick={props.click}>
                   <div>
                   <h1>02</h1>
                <p>Solution</p>
                   </div>
                <BsArrowDown/>  
                </div> 



              
                </div>
                <figure className="location-video ">
             
             <video autoPlay loop muted playsInline className="w-100 "> 
             <source src={props.video} type="video/mp4" />
             </video>
   
         </figure>
               

            </div>
            </div>
    )
}

export default ThirdSection;