import React, { useRef, useState } from "react";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from "axios";

const Contact = () => {
  const [file, setFile] = useState([]);
  //const [fileMeta, setFileMeta] = useState([]);

    const SignupSchema = Yup.object().shape({
        firstName: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        lastName: Yup.string()
          .min(2, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
      });

    return (
        <div className="job-form w-30">
        <h1 >Get in touch</h1>
         <Formik
       initialValues={{
         firstName: '',
         lastName: '',
         email: '',
         upload: '',
       }}
       validationSchema={SignupSchema}
       onSubmit={values => {

           var formData = new FormData();
           formData.append('file', file[0]);
            axios.post("https://cmsbbm.blackbird.marketing/wp-json/wp/v2/media/",formData, {
            headers: {
              "Content-Type": "multipart/form-data; charset=utf-8",
              Authorization: "Bearer " + process.env.REACT_APP_WP_AUTH_KEY,
            },
          }).then((res)=>{

            const data = {
              "title":"Job Application" ,
              "status": "pending",
              "fields":
               {
                   "first_name": values.firstName,
                   "last_name": values.lastName,
                   "email": values.email,
                   "phone": values.phone,
                   "linkedin_profile": values.linkedin_profile,
                   "resume": res.data.id
               }
             }
            fetch("https://cmsbbm.blackbird.marketing/wp-json/wp/v2/jobapplications", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + process.env.REACT_APP_WP_AUTH_KEY,
              },
              body: JSON.stringify(data),
            });
          }).catch((err)=>{ 
            console.log(err);
          });
       }}
     >
         {({ errors, touched }) => (
         <Form>
             <input type="text" name="to_name" value="Blackbird" hidden/>
             <input type="text" name="to_job" value="Data Engineer" hidden/>
             <label htmlFor="name">First Name</label>
             <Field  type="text" name="firstName" id="name"/>
             {errors.firstName && touched.firstName ? (
             <div>{errors.firstName}</div>
           ) : null}
             <label htmlFor="last">Last Name</label>
             <Field  type="text" name="lastName" id="last"/>
             {errors.lastName && touched.lastName ? (
             <div>{errors.lastName}</div>
           ) : null}
             <label htmlFor="email">Email</label>
             <Field  type="email" name="email" id="email"/>
             {errors.email && touched.email ? <div>{errors.email}</div> : null}
             <label htmlFor="phone">Phone</label>
             <Field  type="text" name="phone" id="phone"/>
             <label htmlFor="phone">Linkedin Profile </label>
             <Field  type="text" name="linkedin_profile" id="linkedin_profile"/>
             <label htmlFor="email" class="upload">Resume</label>  
             <input  
              type="file"
              name="upload"
              id="upload"
              required
              onChange={(e) => {
              setFile(e.target.files);
              }}
             /> 
             
             <input type="submit" name="send" value="Apply" className="job-submit"/>
         </Form>
 
         )}
     </Formik>
        </div>
    )
}

export default Contact;