import React, { useEffect, useRef, useState } from "react";
import CareerText from "../Components/Layout/careersText";


import FirstSection from "../Projects/backgroundPro";
import SecondSection from "../Projects/Values";
import ThirdSection from "../Projects/Location";
import FourthSection from "../Projects/Portal";
import FifthSection from "../Projects/Gradient";
import SixthSection from "../Projects/number";
import SeventhSection from "../Projects/Client";
import EighthSection from "../Projects/Phone";
import TenthSection from "../Projects/secondBanner";
import NinthSection from "../Projects/webDesign";
import EleventhSection from "./Collage";
import FlyPages from "../Components/Layout/flyPages";

const Three = () =>{
    const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
    const ValueBox = [
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
        {
            expertice:"Expertice",
            branding:"Branding",
            print:"Print Design"
        },
    ]
    
const  gradient = [
    {
        camera:"with ultra wide-angle camera"
    },
    {
        camera:"with the main camera"
    },
    {
        camera:"with the tele camera"
    },
  ]

    const secondText =
    {
        text1:"Three60",
       text2:"Want to work in a beautiful office in Prishtina, Sofia, or Skopje? Check. Want to work with the latest technologies and the most talented people around you?",

    }
    const collage = [
        {
            image:"../images/three1.webp",
            class:"collage-img1"
        },
        {
            image:"../images/three2.webp",
            class:"collage-img2"
        },
        {
            image:"../images/three3.webp",
            class:"collage-img2"
        },
        {
            image:"../images/three4.webp",
            class:"collage-img1"
        },
     ]

     useEffect(() => {
      
        const handleResize = () => changeDeviceSize(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, []);
    
     const ref = useRef(null);
     const handleClick = () => {
    
      ref.current?.scrollIntoView({ behavior: "smooth" });
      
     };
    return (
       <>
        <div className="container">

        <CareerText right={secondText.text1} left={secondText.text2}  link={secondText.link}/>


        </div>
        <FirstSection image="../images/three.webp" mobile="../images/threemobile.png"/>
         
        <SecondSection   item={ValueBox}/>
        <ThirdSection function={handleClick} video="../images/ylli.mp4" class="three-color"/>
        {/* <FourthSection/> */}
        {/* <FifthSection gradient={gradient}/> */}
        {/* <SixthSection/> */}

        {/* <SeventhSection video={"../images/finser.mp4"}/> */}

        {/* <EighthSection/> */}
        <NinthSection image="../images/threecard.webp" description="Want to work in a beautiful office in Prishtina, Sofia, or Skopje? Check. Want to work with the latest technologies and the most talented people around you?"/>
        <div ref={ref}>
        <TenthSection secondImage="../images/60.png" image="../images/threebanner.webp"  class="three-color-red"/>
        </div>
        <EleventhSection image={collage}/>
        <FlyPages class="three-color-red"/>
        </>
    )
}

export default Three;